
jQuery(document).ready(function () {
    // $('.typeHeader').click(function () {
    //     var t = $(this);
    //     if (!t.next().hasClass('hiddenType')) {
    //         t.next().stop().slideUp(500).addClass('hiddenType');
    //         t.find('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down')
    //     } else {
    //         t.next().stop().slideDown(500).removeClass('hiddenType');
    //         t.find('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
    //     }
    // });
    // $('body').on('click', '.alert-message i.fa-times', function () {
    //     $(this).closest('.row').hide(300, function () {
    //         $(this).find('ul').empty();
    //     });
    // });

    var subscribeForm = $('#form-subscriber');
    var popup = $('#popup-message');


    subscribeForm.submit(function (e) {

       let formData = subscribeForm.serializeArray();

        e.preventDefault();
        $.ajax({
            type: "POST",
            url: url + '/news-subscribe',
            dataType: 'json',
            data: formData,
            success: function (data) {
                popup.removeClass('alert-danger').addClass('alert-success');
                popup.find('#text-popup').text(data.message);
                popup.find('.glyphicon').removeClass('glyphicon-remove').addClass('glyphicon-ok');
                popup.fadeIn(300, function(){
                    popup.fadeOut(2000);
                });
            },
            error: function (data) {
                let errors = $.parseJSON(data.responseText);
                popup.removeClass('alert-success').addClass('alert-danger');
                popup.find('.glyphicon').removeClass('glyphicon-ok').addClass('glyphicon-remove');
                popup.find('#text-popup').text(errors.error);
                popup.fadeIn(300, function(){
                    popup.fadeOut(2000);
                });
            },
            headers: {'X-CSRF-TOKEN': token}
        });
    });


    $('body').on('click','.increment-up',function(){
        let t = $(this);
        let max = parseFloat(t.data('max'));
        const min = parseFloat(t.data('min'));
        let quantityEl = $(this).closest('.quantity').find('.quantity-increment');
        let v = parseFloat(quantityEl.val());
        if(!v){
            v = min? min : 0;
        }
        // if(t.hasClass('check-options')){
        //     let optionsObjects = t.closest('form').find('#product-options-container').find('.option-select').find('option:selected');
        //     let m = false;
        //     optionsObjects.each(function(){
        //         let t = $(this);
        //         if(typeof t.data('max') != 'undefined'){
        //             let tmax = parseFloat(t.data('max'));
        //             if( m === false || tmax < m){
        //                 m = tmax;
        //             }
        //         }
        //         // console.log($(this).data('max'));
        //     });
        //     if(m !== false && m < max){
        //         max = m;
        //     }
        //     console.log('optionsObjects',optionsObjects);
        // }

        // alert(v+1);
        // alert(max);
        // alert((v+1)<=max);
        if((v+1)<=max){
            quantityEl.val(v+1);
            quantityEl.trigger('change');
        } else {
            if(max > 0){
                swal('', trans('app.available')+' '+ v, 'info');
            } else {
                swal('', 'For current time this product not available', 'info');
            }
        }

    });
    $('body').on('click','.increment-down',function(){
        let quantityEl = $(this).closest('.quantity').find('.quantity-increment');
        let v = quantityEl.val();
        let t = $(this);
        let min = 0;
        if(t.data('min')){
            min = parseFloat(t.data('min'));
        }

        quantityEl.val((v-1 <= min)? min : (v-1));
        quantityEl.trigger('change');
    });


    $('body').on('click','.add-to-cart', function (e) {
        var t = $(this);
        e.preventDefault();

        let prod_id = t.data('prod-id');

        confirmAddingProd(t,prod_id, 'single_product');
    });

    $('body').on('click','.add-to-cart-group', function (e) {
        var t = $(this);
        e.preventDefault();

        let group_id = t.data('group-id');

        confirmAddingProd(t,group_id, 'products_group');
    });




    const previewProd_form = $('#product-form');
    if(previewProd_form.length > 0) {
        const previewProd_parsley = previewProd_form.parsley();
        // $(document).ready(function(){
            $('#product-form').on('submit', function(){
                previewProd_parsley.validate();

                if (previewProd_parsley.isValid()) {
                    let t = previewProd_form.find('#add-product');
                    confirmAddingProd(t, t.data('prod-id'), 'single_product');
                    return false;
                }

                return false;
            });
        // });
    }


//cookie block
    $('#customize-box-btn').on('click', function(){
        $('#c-common-content').css('display','none');
        $('#c-customize-content').css('display','inline-block');
    });
    $('#back-from-customize-btn').on('click', function(){
        $('#c-common-content').css('display','inline-block');
        $('#c-customize-content').css('display','none');
    });
    $('#apply-cookie-checkbox').on('change', function(){
        let t = $(this);

        if(t.prop('checked')){

            let v = JSON.stringify({
                targeting_cookie:($('#targeting-cookie').prop('checked')?1:0),
                required_cookie:1,
                applied_by_user:1,
                // required_cookie:coockieData.required_cookie,
            });
            // setCookie('user', v, {secure: false, 'max-age': 3600});
            setCookie('cookie_files_policy', v, {'max-age': (60*60*24*365)});

            $('#coockie-modal').modal('hide');
        }

    });
    $('#discard-cookie-btn').on('click', function(){
        let t = $(this);

            let v = JSON.stringify({
                targeting_cookie:0,
                required_cookie:0,
                applied_by_user:0,

                // required_cookie:coockieData.required_cookie,
            });
            // setCookie('user', v, {secure: false, 'max-age': 3600});
            setCookie('cookie_files_policy', v, {'max-age': (60*60*24*365)});

        $('#coockie-modal').modal('hide');
    });

// let coockieData = getCookie('cookie_files_policy');
// console.log('coockieData',coockieData);

if(LA.show_coockie_modal == 1){
    setTimeout(function(){
        $('#coockie-modal').modal('show');
    },2000);
}


//end cookie block
});


window.getCookie = function (name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

window.setCookie = function (name, value, options = {}) {
    options = Object.assign({
        path: '/',
        domain: LA.domain //leading dot using for subdomains
    },options);

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};
window.deleteCookie = function(name) {
    setCookie(name, "", {
        'max-age': -1
    })
};
// setCookie('user', 'John', {secure: true, 'max-age': 3600});

window.confirmAddingProd = function (t, item_id, item_type, withFormData){

    swal(trans('messages.confirmation_add_to_shop_cart'), {
        icon:'info',
        buttons: {
            cancel: trans("app.cancel"),
            defeat: trans("app.add"),
            // catch: {
            //     text: "To shop cart",
            //     value: "add_and_redirect",
            // },
        },
    })
        .then((value) => {
            switch (value) {

                case "defeat":
                    //                 var countObj = $('.basket').find('.order-count');
                    //                 var count = parseInt(countObj.text());
                    //                 countObj.text(count + 1);
                    addToCart( item_id, item_type,withFormData);

                    break;

                // case "add_and_redirect":
                //     addToCart( item_id, item_type, '/shopcart');
                //     // swal("Gotcha!", "Pikachu was caught!", "success");
                //     break;

                default:
                // swal("Got away safely!");
            }
        });

}

window.addToCart =  function (item_id, item_type, withFormData) {

    let data = [];

    if(withFormData) {
        data = $(withFormData).serializeArray();
    }else if((item_type == 'single_product' && $('#product-form').length)){
        //review page

        if($('#product-form').find('#quantity_wanted').val() <= 0){
            swal('', 'For current time this product not available', 'info');
            return;
        }
        data = $('#product-form').serializeArray();
    } else {
        data.push({name:'use_default_options', value: 1});
    }

    data.push({name:'item_id', value: item_id});
    data.push({name:'item_type', value: item_type});

    $.ajax({
        type: "POST",
        url: LA.base_url+'/shopcart/add-item',
        dataType: 'json',
        data: data,
        success: function (data) {

            if(data.sweet_error){
                window.processResponseError(data);
            } else {
                $('.cart-products-count').text(data.quantity);
                $('.cart-sum').text(parseFloat(data.sum).toFixed(2));
                $('#purchase-btn').removeClass('hidden');
                // swal('',"Successfully added to shopping cart!", 'success');
                $(document).trigger({type:'productAddedToShopcart', event_data:data});
                swal(trans("messages.added_to_shopcart"), {
                    icon:'success',
                    buttons: {
                        cancel: trans("app.continue"),
                        defeat: trans('app.open_shopcart'),
                        // catch: {
                        //     text: "To shop cart",
                        //     value: "add_and_redirect",
                        // },
                    },
                })
                    .then((value) => {
                        switch (value) {

                            case "defeat":
                                window.location.href = LA.base_url + '/shopcart';
                                //                 var countObj = $('.basket').find('.order-count');
                                //                 var count = parseInt(countObj.text());
                                //                 countObj.text(count + 1);
                                // addToCart( item_id, item_type);

                                break;

                            // case "add_and_redirect":
                            //     addToCart( item_id, item_type, '/shopcart');
                            //     // swal("Gotcha!", "Pikachu was caught!", "success");
                            //     break;
                            //
                            // default:
                            // swal("Got away safely!");
                        }
                    });
                // }
                // if (data.success) {
                //     $('.success-ajax-container').css('display', 'block').find('ul').append('<li>' + data.success + '</li>');
                // }
            }



        },
        error: function (data) {
            window.processResponseErrors(data);
            // var errors = $.parseJSON(data.responseText);
            //
            // $.each(errors.errors, function (key, value) {
            //
            //     $('.error-ajax-container').css('display', 'block').find('ul').append('<li>' + value + '</li>');
            // });

        },
        headers: {'X-CSRF-TOKEN': LA.token}
    });
}



$('body').on('click', '.expander-element', function(){
    let container = $(this).closest('.expander-container');
    let t =$(this);
    if(container.hasClass('half-opened')){
        // alert('ope//ning');
        container.animate({
            height: '500px', //auto  does not work
            overflow: 'none'
        }, 600, function(){
            container.removeClass('half-opened');
            t.removeClass('fa-chevron-down').addClass('fa-chevron-up');
        });

    } else {
        // alert('closing');
        container.animate({
            height: '74px',
            overflow: 'hidden'
        }, 600, function(){
            container.addClass('half-opened');
            t.addClass('fa-chevron-down').removeClass('fa-chevron-up');
        });

    }
});


// console.log(confirmAddingProd);
