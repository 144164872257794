//run /build-front-translations in browser 
 export const translations = {
    "pl": {
        "validation": {
            "strong_password": {
                "length_and_upper_letter": "Pole :attribute musi składać się z co najmniej :length znaków i zawierać co najmniej jedną wielką literę.",
                "length_and_number": "Pole :attribute musi składać się z co najmniej :length znaków i zawierać co najmniej jedną cyfrę.",
                "length_and_special_char": "Pole :attribute musi mieć co najmniej :length znaków i zawierać co najmniej jeden znak specjalny.",
                "length_and_upper_char_and_number": "Pole :attribute musi mieć co najmniej :length znaków i zawierać co najmniej jedną wielką literę i jedną cyfrę.",
                "length_and_upper_char_and_special_char": "Pole :attribute musi mieć co najmniej :length znaków i zawierać co najmniej jedną wielką literę i jeden znak specjalny.",
                "length_and_upper_char_and_special_char_and_number": "Pole :attribute musi mieć co najmniej :length znaków i zawierać co najmniej jedną wielką literę, jedną cyfrę i jeden znak specjalny.",
                "length": "Pole :attribute musi zawierać co najmniej :length znaków."
            },
            "accepted": "The :attribute must be accepted.",
            "accepted_if": "The :attribute must be accepted when :other is :value.",
            "active_url": "The :attribute is not a valid URL.",
            "after": "The :attribute must be a date after :date.",
            "after_or_equal": "The :attribute must be a date after or equal to :date.",
            "alpha": "The :attribute must only contain letters.",
            "alpha_dash": "The :attribute must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The :attribute must only contain letters and numbers.",
            "array": "The :attribute must be an array.",
            "ascii": "The :attribute must only contain single-byte alphanumeric characters and symbols.",
            "before": "The :attribute must be a date before :date.",
            "before_or_equal": "The :attribute must be a date before or equal to :date.",
            "boolean": "The :attribute field must be true or false.",
            "confirmed": "Potwierdzenie :attribute nie pasuje.",
            "current_password": "The password is incorrect.",
            "date": "The :attribute is not a valid date.",
            "date_equals": "The :attribute must be a date equal to :date.",
            "date_format": "The :attribute does not match the format :format.",
            "decimal": "The :attribute must have :decimal decimal places.",
            "declined": "The :attribute must be declined.",
            "declined_if": "The :attribute must be declined when :other is :value.",
            "different": "The :attribute and :other must be different.",
            "digits": "The :attribute must be :digits digits.",
            "digits_between": "The :attribute must be between :min and :max digits.",
            "dimensions": "The :attribute has invalid image dimensions.",
            "distinct": "The :attribute field has a duplicate value.",
            "doesnt_end_with": "The :attribute may not end with one of the following: :values.",
            "doesnt_start_with": "The :attribute may not start with one of the following: :values.",
            "email": "The :attribute must be a valid email address.",
            "ends_with": "The :attribute must end with one of the following: :values.",
            "enum": "The selected :attribute is invalid.",
            "exists": "The selected :attribute is invalid.",
            "file": "The :attribute must be a file.",
            "filled": "The :attribute field must have a value.",
            "image": "The :attribute must be an image.",
            "in": "The selected :attribute is invalid.",
            "in_array": "The :attribute field does not exist in :other.",
            "integer": "The :attribute must be an integer.",
            "ip": "The :attribute must be a valid IP address.",
            "ipv4": "The :attribute must be a valid IPv4 address.",
            "ipv6": "The :attribute must be a valid IPv6 address.",
            "json": "The :attribute must be a valid JSON string.",
            "lowercase": "The :attribute must be lowercase.",
            "mac_address": "The :attribute must be a valid MAC address.",
            "max_digits": "The :attribute must not have more than :max digits.",
            "mimes": "The :attribute must be a file of type: :values.",
            "mimetypes": "The :attribute must be a file of type: :values.",
            "min_digits": "The :attribute must have at least :min digits.",
            "multiple_of": "The :attribute must be a multiple of :value.",
            "not_in": "The selected :attribute is invalid.",
            "not_regex": "The :attribute format is invalid.",
            "numeric": "The :attribute must be a number.",
            "present": "The :attribute field must be present.",
            "prohibited": "The :attribute field is prohibited.",
            "prohibited_if": "The :attribute field is prohibited when :other is :value.",
            "prohibited_unless": "The :attribute field is prohibited unless :other is in :values.",
            "prohibits": "The :attribute field prohibits :other from being present.",
            "regex": "The :attribute format is invalid.",
            "required": "The :attribute field is required.",
            "required_array_keys": "The :attribute field must contain entries for: :values.",
            "required_if": "The :attribute field is required when :other is :value.",
            "required_if_accepted": "The :attribute field is required when :other is accepted.",
            "required_unless": "The :attribute field is required unless :other is in :values.",
            "required_with": "The :attribute field is required when :values is present.",
            "required_with_all": "The :attribute field is required when :values are present.",
            "required_without": "The :attribute field is required when :values is not present.",
            "required_without_all": "The :attribute field is required when none of :values are present.",
            "same": "The :attribute and :other must match.",
            "starts_with": "The :attribute must start with one of the following: :values.",
            "string": "The :attribute must be a string.",
            "timezone": "The :attribute must be a valid timezone.",
            "unique": "The :attribute has already been taken.",
            "uploaded": "The :attribute failed to upload.",
            "uppercase": "The :attribute must be uppercase.",
            "url": "The :attribute must be a valid URL.",
            "ulid": "The :attribute must be a valid ULID.",
            "uuid": "The :attribute must be a valid UUID.",
            "between": {
                "array": "The :attribute must have between :min and :max items.",
                "file": "The :attribute must be between :min and :max kilobytes.",
                "numeric": "The :attribute must be between :min and :max.",
                "string": "The :attribute must be between :min and :max characters."
            },
            "gt": {
                "array": "The :attribute must have more than :value items.",
                "file": "The :attribute must be greater than :value kilobytes.",
                "numeric": "The :attribute must be greater than :value.",
                "string": "The :attribute must be greater than :value characters."
            },
            "gte": {
                "array": "The :attribute must have :value items or more.",
                "file": "The :attribute must be greater than or equal to :value kilobytes.",
                "numeric": "The :attribute must be greater than or equal to :value.",
                "string": "The :attribute must be greater than or equal to :value characters."
            },
            "lt": {
                "array": "The :attribute must have less than :value items.",
                "file": "The :attribute must be less than :value kilobytes.",
                "numeric": "The :attribute must be less than :value.",
                "string": "The :attribute must be less than :value characters."
            },
            "lte": {
                "array": "The :attribute must not have more than :value items.",
                "file": "The :attribute must be less than or equal to :value kilobytes.",
                "numeric": "The :attribute must be less than or equal to :value.",
                "string": "The :attribute must be less than or equal to :value characters."
            },
            "max": {
                "array": "The :attribute must not have more than :max items.",
                "file": "The :attribute must not be greater than :max kilobytes.",
                "numeric": "The :attribute must not be greater than :max.",
                "string": "The :attribute must not be greater than :max characters."
            },
            "min": {
                "array": "The :attribute must have at least :min items.",
                "file": "The :attribute must be at least :min kilobytes.",
                "numeric": "The :attribute must be at least :min.",
                "string": "Pole :attribute musi mieć co najmniej 8 znaków"
            },
            "password": {
                "letters": "The :attribute must contain at least one letter.",
                "mixed": "The :attribute must contain at least one uppercase and one lowercase letter.",
                "numbers": "The :attribute must contain at least one number.",
                "symbols": "The :attribute must contain at least one symbol.",
                "uncompromised": "The given :attribute has appeared in a data leak. Please choose a different :attribute."
            },
            "size": {
                "array": "The :attribute must contain :size items.",
                "file": "The :attribute must be :size kilobytes.",
                "numeric": "The :attribute must be :size.",
                "string": "The :attribute must be :size characters."
            },
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "new1": "ar14",
            "new5": "ddd11"
        },
        "passwords": {
            "reset": "Twoje hasło zostało zresetowane!",
            "sent": "Wysłaliśmy e-mail z linkiem do resetowania hasła!",
            "throttled": "Proszę czekać przed ponowną próbą.",
            "token": "Proszę czekać przed ponowną próbą.",
            "user": "Nie możemy znaleźć użytkownika o tym adresie e-mail."
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;",
            "app": {
                "shown_num_items_from_num": "Test"
            }
        },
        "new": {
            "sending_message_in_queue": "",
            "main_category_should_not_be_parent": "",
            "confirmation_add_to_shop_cart": "",
            "hello": "",
            "thanks": "",
            "regards": "",
            "added_to_shopcart": "",
            "successfully_deleted": "",
            "confirm_order_deleting": "",
            "payment_order_made_message": "",
            "reset_pass_desc": "",
            "reset_pass_trouble_info": "",
            "mail_successfully_send": "",
            "mail_not_send": "",
            "new_question": "sds"
        },
        "messages": {
            "sending_message_in_queue": "Sending message in queue",
            "main_category_should_not_be_parent": "You cannot specify parent category",
            "confirmation_add_to_shop_cart": "Czy na pewno chcesz dodać produkt do koszyka?",
            "hello": "Witaj",
            "thanks": "Dzięki",
            "regards": "Pozdrowienia",
            "added_to_shopcart": "Pomyślnie dodano do koszyka!",
            "successfully_deleted": "Pomyślnie usunięto",
            "confirm_order_deleting": "Zdecydowałeś się usunąć zamówienie. Kontynuujemy?",
            "payment_order_made_message": "Płatność została dokonana pomyślnie. Dziękuję za Twoje zamówienie",
            "reset_pass_desc": "Otrzymujesz tę wiadomość e-mail, ponieważ otrzymaliśmy prośbę o zresetowanie hasła do Twojego konta",
            "reset_pass_trouble_info": "Jeśli masz problem z kliknięciem przycisku „Zresetuj hasło”, skopiuj i wklej poniższy adres URL\ndo Twojej przeglądarki internetowej",
            "mail_successfully_send": "Wiadomość została wysłana pomyślnie",
            "mail_not_send": "Wiadomość nie została wysłana",
            "new_question": "Nowe pytanie",
            "customize_cookie_message": "Możesz skonfigurować pliki cookie, które są dla Ciebie wygodne, z wyjątkiem tych niezbędnych, których anulowanie może wpłynąć na działanie witryny",
            "required_cookie_fields_message": "Te pola cookie są niezbędne do działania witryny i nie można ich wyłączyć. Czy możesz ustawić swoją przeglądarkę tak, aby blokowała te pola cookie, ale w takim przypadku możliwe jest, że niektóre sekcje witryny przestaną działać",
            "targeting_cookie_fields_message": "Te pola cookie są konfigurowane za pośrednictwem naszej witryny przez naszych partnerów. Mogą być wykorzystywane do zbierania danych o Twoich zainteresowaniach, odwiedzanych stronach i źródłach ruchu w celu mierzenia i ulepszania naszej strony internetowej",
            "cookie_apply_description": "Klikając przycisk ':apply' wyrażasz zgodę na przetwarzanie plików cookies zgodnie z :policy_link",
            "cookie_policy_link": "Polityką plików cookie",
            "cookie_common_message": "Możemy przetwarzać Twoje dane osobowe i używać technologii takich jak pliki cookies w celu świadczenia usług oraz prowadzenia statystyk. Możesz w każdej chwili zmienić swój wybór, klikając &quot;Ustawienia prywatności&quot; w stopce strony i\/lub w ustawieniach przeglądarki. Korzystając ze strony, zgadzasz się z warunkami przetwarzania danych osobowych zawartymi w polityce prywatności.",
            "cookie_policy_desc": "Ten plik cookie jest używany do zapamiętywania preferencji dotyczących zgody użytkownika na pliki cookie.",
            "cookie_xsrf-token_desc": "Ten plik cookie zapisano, aby pomóc w zabezpieczeniu witryny i zapobieganiu atakom typu Cross-Site Request Forgery",
            "cookie_laravel_session_desc": "Wewnętrznie aplikacja używa :coockie_name do identyfikacji instancji sesji dla użytkownika",
            "targeting_coockie_desc": "Pliki cookie ukierunkowane są używane do identyfikowania odwiedzających między poszczególnymi stronami internetowymi, np. partnerami tworzącymi treści, sieciami reklamowymi. Te pliki cookie mogą być wykorzystywane przez firmy do tworzenia profili zainteresowań użytkowników lub wyświetlania odpowiednich reklam na innych stronach internetowych.",
            "coockie_common_info": " Pliki cookie to małe pliki tekstowe przechowywane na Twoim komputerze przez strony internetowe, które przeglądasz. Strony internetowe korzystają z plików cookie w celu ułatwienia użytkownikom sprawnej nawigacji i wykonywania określonych funkcji. Pliki cookie, które są niezbędne do prawidłowego funkcjonowania strony internetowej, nie wymagają Twojej zgody. Wszystkie inne pliki cookie wymagają zgody przed ustawieniem ich obsługi w przeglądarce.",
            "sending_notifications_acceptation": "Zezwalam na przesyłanieinformacjina maila",
            "using_private_data_acceptation": "Zezwolam na użycie moich danych",
            "read_and_accepted": "Przeczytałem i zaakceptowałem",
            "policy_acceptation": "Ich erkläre, dass ich die <a class=\"acceptation-link\" href=\":policy_route\" target=\"_blank\" >:policy_link<\/a> gelesen habe. Ich erkläre, dass ich die <a class=\"acceptation-link\" href=\":acceptation_deny_route\" target=\"_blank\" >:acceptation_deny_link<\/a> gelesen habe",
            "updated_successfully": "Zaktualizowano pomyślnie",
            "deleted_successfully": "Usunięto pomyślnie",
            "created_successfully": "Utworzono pomyślnie",
            "added_successfully": "Dodano pomyślnie",
            "imported_successfully": "Zaimportowano pomyślnie",
            "something_was_wrong": "Oops. Something was wrong",
            "slug_exists_in_locale": "Taki slug już istnieje w tej lokalizacji",
            "order_without_parcels": "Zamówienie nie obejmuje przesyłek",
            "no_parcel_weight": "Nie podano wagi przesyłki",
            "parcels_not_chosen": "Żadna przesyłka nie została wybrana",
            "thanks_for_order": "Dziękuję za zamówienie",
            "we_are_sorry": "Przykro nam",
            "copyright": "Prawa autorskie © :current_year. Projekt autorstwa TivaTheme. Wszelkie prawa zastrzeżone",
            "to_big_size": "Too big size",
            "def_color_was_not_set": "Default color for box was not set or price (element - color group) was not set",
            "rol_sizes_error": "Rol sizes error",
            "shield\/box_price_was_not_set": "Shield box price for chosen sizes was not set",
            "such_box_doesnt_exist": "Such box does not exist",
            "such_structure_doesnt_exist": "Such structure does not exist",
            "such_shield_doesnt_exist": "Such shield does not exist",
            "such_box_seria_doesnt_exist": "Such box seria does not exist",
            "such_box_sizes_not available": "Such box sizes not available or was not set",
            "default_color_for_shield_was_not_set": "Default color for shield was not set",
            "default_color_for_bottom_band_was_not_set": "Default color for bottom band was not set",
            "confirmation_transfer_payment": "Potwierdzenie płatności przelewem",
            "transfer_payment_request_is_processing": "Żądanie przelewu jest przetwarzane"
        },
        "configurators": {
            "box": "Skrzynka",
            "boxes": "Skrzynki",
            "system": "Układ",
            "hole_dimensions": "Wymiary otworu",
            "holes_dimensions": "Wymiar otworu\/lub wymiary otworu",
            "shield_type": "Typ pancerza",
            "shield_color": "Kolor pancerzu",
            "bottom_band_color": "Kolor listwy dolnej",
            "selected_structure_size": "Rozmiar wybranego kształtu",
            "box_color": "Kolor skrzynki",
            "guide_color": "Kolor prowadnic",
            "guide_rotation": "Wiercenie prowadnic",
            "manage_type": "Typ napędu",
            "roll_managing": "Sterowanie rolet",
            "manage_side": "Strona sterowania",
            "mosquito_net": "Moskitiera",
            "width": "Szerokosc",
            "height": "Wysokosc",
            "qty": "Ilość",
            "shield_not_available": "Panczeż niedostępny dla wybranych rozmiarów. Proszę zmienić rozmiar",
            "cm": "cm",
            "hatch_color": "Kolor klapy rewizyjnej\/listwy tynkowej",
            "plasterboard_type": "Typ listwy tynkowej",
            "box_sizes_not_available_or_not_chosen": "Rozmiary skrzynek niedostępne lub nie wybrane",
            "manage_type_not_available": "Podane wymiary uniemożliwiają wyprodukowanie takiej rolety",
            "remotes": "Piloty",
            "engines": "Silniki",
            "remote": "Pilot",
            "shields": "Skrzynki",
            "shield": "Skrzynka",
            "shield_sizes": "Rozmiary Skrzynki",
            "includes_mosquito_net": "Zawiera moskitierę",
            "show_wood_colors": "Pokazuj kolory drewnopodobne",
            "prices_of_box_with_shield": "Ceny na skrzynku z pancerzem",
            "box_sizes": "Rozmiary skrzynki",
            "box_size": "Rozmiar skrzynki",
            "engine_type": "Typ silnika",
            "roll_type": "Typ rolety",
            "roll_subtype": "Podtyp rolety",
            "roll_subtypes": "Podtypy rolety",
            "percent_per_color_group": "Procent za grupu kolorow",
            "elements_for_coloring": "Elementy do barwienia",
            "engine_group_remotes": "Piloty grupy silników",
            "engine_types": "Typy silników",
            "rotation_types": "Typy wiercenia",
            "manage_sides": "Strony sterowania",
            "drive_types_options": "Typy napędu (opcje)",
            "plasterboard_types": "Typy listwy tynkowej",
            "configurator_images": "Obrazy konfiguratora",
            "roll_structure": "Układ rolet",
            "pairs_number_of_size_fields": "Liczba par pól rozmiaru",
            "colors_related_to_box": "Kolory związane ze Skrynką",
            "colors_related_to_shield": "Kolory związane ze Pancerzem",
            "colors_related_to_manual_driving": "Kolory związane z typom napędu Ręczny",
            "colors_sl_elements": "Kolory\/Elementy",
            "clinet_file": "Klapa rewizyjna\/listwa tynkowa"
        },
        "auth": {
            "failed": "Te dane uwierzytelniające nie pasują do naszych danych.",
            "password": "",
            "throttle": "",
            "verify_your_email": "Zweryfikuj swój email",
            "check_your_email": "Proszę sprawdzić swoją skrzynkę pocztową",
            "if_didnt_get_link": "Jeśli nie masz linku, naciśnij aby",
            "get_another_link": "dostać inny link",
            "verify_click_text": "Kliknij przycisk poniżej, aby zweryfikować swój adres e-mail",
            "verify_email": "Zweryfikuj",
            "mail_click_trouble": "Jeśli masz problem z kliknięciem przycisku \":actionText\" skopiuj i wklej adres URL poniżej\ndo przeglądarki: [:actionURL](:actionURL)",
            "create_account": "Utwórz konto",
            "log_in": "Zaloguj sie",
            "are_you_forgot_your_pass": "Zapomniałeś hasła?",
            "reset_password": "Zresetować hasło"
        },
        "attribut": {
            "title": "",
            "description": {
                "index": "",
                "show": "",
                "edit": "",
                "create": ""
            }
        },
        "app": {
            "nav": {
                "howToMeasure": "jak-mierzyc",
                "gdpr": "rodo",
                "aboutUs": "o-nas",
                "contacts": "kontakt",
                "howToMount": "jak_montowac"
            },
            "status": "Status",
            "status_active": "Aktywny",
            "status_inactive": "Nieaktywny",
            "yes": "Tak",
            "no": "Nie",
            "locale": "Locale",
            "new": "Nowy",
            "admin_panel_header": "Panel administratora",
            "home_page": "Strona główna",
            "categories": "Kategorie",
            "new_products": "Nowe Produkty",
            "products_available": "Dostępno",
            "catalog": "Katalog",
            "buy_together": "Kup razem",
            "attributes": "Atrybuty",
            "options": "Opcje",
            "paired_products": "Kup razem",
            "related_products": "Produkty powiązane",
            "login": "Zaloguj sie",
            "registration": "Rejestracja",
            "shop_cart": "Koszyk",
            "password": "Hasło",
            "email": "E-mail",
            "first_name": "Imię",
            "last_name": "Nazwisko",
            "create": "Utworz",
            "password_confirmation": "Potwierdzenie hasła",
            "phone": "Telefon",
            "firm_name": "Nazwa firmy",
            "new_password": "Nowe hasło",
            "nip": "NIP",
            "save": "Zapisz",
            "edit": "Edituj",
            "menu": "Menu",
            "edit_profile": "Edytuj profil",
            "orders_history": "Historia zamówień",
            "my_account": "Moje konto",
            "account": "Konto",
            "logout": "Wyloguj",
            "address": "Adres",
            "country": "Kraj",
            "region": "Region",
            "city": "Miasto",
            "street": "Ulica",
            "post_code": "Kod pocztowy",
            "purchase": "Zapłać",
            "private_person": "Osoba prywatna",
            "account_type": "Typ konta",
            "firm": "Firma",
            "personal_information": "Informacje osobiste",
            "rol_rotation_a": "Wiercenie prowadnic A",
            "rol_rotation_b": "Wiercenie prowadnic B",
            "color": "Kolor",
            "products": "Towary",
            "status_new": "Nowy",
            "status_in_progress": "W trakcie",
            "status_rejected": "Odrzucony",
            "status_taken": "Otrzymane",
            "status_is_send": "Wysłano",
            "status_returned": "Zwrócony",
            "status_confirmed": "Potwierdzony",
            "send": "Wyślij",
            "message": "Wiadomość",
            "discount": "Zniżka",
            "discount_in_percent": "Zniżka w procentach",
            "date_from": "Od",
            "date_to": "Do",
            "is_active": "Aktywny",
            "coupon": "Kupon",
            "search": "Szukaj",
            "search_descr": "ZNAJDŹ SWÓJ PRODUKT ZA POMOCĄ SZYBKIEGO WYSZUKIWANIA. WPISZ JAKIEŚ SŁOWA KLUCZOWE, TAKIE JAK SUKIENKA, KOSZULKI, BUTY ITP. LUB MOŻNA WYSZUKIWAĆ WEDŁUG SKU PRODUKTU.",
            "value_is_translatable": "Z tłumaczeniem",
            "select": "Wybierz",
            "description": "Opis",
            "reviews": "Opinie",
            "free_delivery_from": "BEZPŁATNA DOSTAWA OD",
            "full_name": "Imię i nazwisko",
            "submit": "Zatwierdź",
            "comment": "Komentarz",
            "your_rating": "Twoja ocena",
            "to_cart": "Do koszyka",
            "shop_reviews": "Opinia do sklepu",
            "contact": "Kontakt",
            "unit_g": "gm",
            "unit_mm": "mm",
            "unit_m2": "m2",
            "unit_kg": "kg",
            "unit_cm": "cm",
            "unit_pcs": "szt",
            "send_reset_link": "Wyślij link do resetowania",
            "password_reset": "Resetowania hasła",
            "back_to_login": "Powrót do logowania",
            "password_resetting": "Resetowanie hasła",
            "agreement_payment_menu": "AGB",
            "contact_us": "Skontaktuj się z nami",
            "engine": "Silnik-1",
            "type": "Typ",
            "mosquito_net": "Moskitiera",
            "open_shopcart": "Otwórz koszyk",
            "continue": "Kontynuować",
            "add": "Dodaj",
            "cancel": "Anuluj",
            "download_pdf": "Pobierz pdf",
            "offer": "Oferta",
            "data": "Data",
            "delivery": "Dostawa",
            "paid": "Opłacono",
            "total": "Całkowicie",
            "quantity_short": "Ilość",
            "review": "Opinię",
            "read_reviews": "Przeczytaj opinie",
            "write_review": "Napisz opinię",
            "items_count_in_shopcart": "W twoim koszyku jest :quantity przedmiot(ów)",
            "total_products": "Produkty ogółem",
            "total_delivery": "Całkowita dostawa",
            "for_free_delivery": "Do darmowej wysyłki",
            "free": "Darmowe",
            "delete": "Usuń",
            "main_data": "Główne dane",
            "returning_form": "Formularz zwrotu",
            "order_number": "Numer zamówienia",
            "returning_reason": "Powód zwrotu",
            "returning": "Zwrót",
            "order": "Zamówienie",
            "free_delivery": "Darmowa dostawa",
            "id": "ID",
            "name": "Nazwa",
            "name_short": "Imię krótkie",
            "title": "Tytuł",
            "meta_title": "Meta tytuł",
            "meta_description": "Meta opis",
            "parent_id": "Rodzic",
            "icon": "Ikona",
            "uri": "Uri",
            "created_at": "Utworzono o godz",
            "updated_at": "Zaktualizowano o godz",
            "discount_placeholder": "Wpisz Rabat",
            "discount_helper": "Rabat w %",
            "remember_token": "Pamiętaj Token",
            "email_verified_at": "E-mail zweryfikowany o godz",
            "main_category_id": "Główna kategoria",
            "main_category_id_helper": "Określ pozycję menu dla produktu",
            "category": "Kategoria",
            "price": "Cena",
            "price_from": "Cena od",
            "price_placeholder": "Wpisz cenę",
            "price_helper": "Cena",
            "img": "Obraz",
            "image": "Obraz",
            "images": "Obrazy",
            "url": "URL",
            "video_url": "Adres URL do wideo",
            "quantity": "Ilość",
            "group": "Grupa",
            "calculation_type": "Typ obliczenia",
            "is_available": "Włączony",
            "deliverymethods_name": "Nazwa metody dostawy",
            "deliverymethods_price": "Koszty wysyłki",
            "productsfixed_main_image": "Główny obraz",
            "productsfixed_images": "Dodatkowe obrazy",
            "options_helper": "Wybierz żądane parametry z listy i wprowadź wartość.",
            "slug": "Slug",
            "slug_help": "Napis będzie widoczny w pasku adresu. Generowane automatycznie lub można ustawić ręcznie",
            "slider_alt_helper": "Jeśli zdjęcia są wyłączone, zostanie wyświetlony komunikat",
            "picture": "Zdjęcie",
            "text": "Tekst",
            "content": "Treść",
            "product": "Produkt",
            "product_name_helper": "Nazwa produktu, która zostanie wyświetlona po kliknięciu na menu określone powyżej",
            "value": "Wartość",
            "bound_categories": "Powiązane kategorie",
            "available": "Dostępne",
            "producer": "Producent",
            "vat": "Vat",
            "price_net": "Cena netto",
            "price_gross": "Cena brutto",
            "new1": "sd",
            "months": {
                "january": "Styczeń",
                "february": "Luty",
                "march": "Marsz",
                "april": "Kwiecień",
                "may": "Maj",
                "june": "Czerwiec",
                "july": "Lipiec",
                "august": "Sierpień",
                "september": "Wrzesień",
                "october": "Październik",
                "november": "Listopad",
                "december": "Grudzień"
            },
            "acceptation": "Acceptations",
            "sender": "Nadawca",
            "recipient": "Odbiorca",
            "house_number": "Numer domu",
            "apply": "Zastosuj",
            "close": "Zamknij",
            "weight": "Waga",
            "boxes_for_sending": "Przesyłki do wysłania",
            "availability": "Dostępność",
            "many_available": "Duża ilość",
            "little_available": "Mała ilość",
            "currently_not_available": "Chwilowo niedostępny",
            "customize": "Dostosuj",
            "back": "Powrót",
            "required_fields_cookie": "Wymagane pola cookie",
            "targeting_fields_cookie": "Docelowe pola cookie",
            "cookie_policy": "Polityka Cookie",
            "shown_num_items_from_num": "Pokazano :shown_num przedmiotow z :total_num",
            "others": "Inne",
            "configure": "Skonfiguruj",
            "name_from_dictionary": "Przekład ze słownika",
            "first_name_and_last_name": "Imię i nazwisko",
            "order_as_guest": "Zamów jako gość",
            "another_delivery_address": "Inny adres wysylki",
            "payment": "Płatność",
            "sending_notifications_acceptation": "Wyrażam zgodę na przesyłanie informacji drogą mailową",
            "using_private_data_acceptation": "Wyrażam zgodę na wykorzystanie moich danych",
            "read_and_accepted": "Przeczytałem i zaakceptowałem",
            "seller_notes": "Uwagi do sprzedawcy",
            "about_us": "O nas",
            "blog": "Blog",
            "read_more": "Czytaj więcej",
            "files": "Pliki",
            "delivery_address": "Adres dostawy",
            "chosen_by_default": "Domyślny",
            "delivery_method": "Sposób dostawy",
            "price_per_kg": "Cena za kg",
            "agreement_to_pay_for_order": "Zgoda na płatność",
            "import": "Import",
            "engine_groups": "Grupa silników",
            "additional_settings": "Dodatkowe ustawienia",
            "subtype": "Podtyp",
            "by_default": "By default",
            "size": "Rozmiar",
            "write_price": "Wpisz cenę",
            "max_height": "Maks wysokość",
            "min_height": "Min wysokość",
            "max_width": "Maks szerokość",
            "min_width": "Min szerokość",
            "colors": "Kolory",
            "editing": "Edytowanie",
            "deleting": "Usuwanie",
            "creating": "Tworzenie",
            "updating": "Aktualizowanie",
            "color_group": "Grupa kolorów",
            "color_groups": "Grupy kolorów",
            "color_subgroup": "Podgrupa kolorów",
            "color_subgroups": "Podgrupy kolorów",
            "create_new": "Utwórz nowe",
            "percent": "Percent",
            "element": "Element",
            "model": "Model",
            "serial_number": "Numer seryjny",
            "calculation_factor": "Czynnik projektowy",
            "example": "Przykład",
            "example_for": "Przykład dla",
            "sizes": "Sizes",
            "colors_are_required": "Kolory są wymagane",
            "orders": "Zamówienia",
            "customer": "Kupujący",
            "max_square": "Maks obszar",
            "weight_per": "Weight per",
            "allowed_size": "Dozwolony rozmiar",
            "main_category": "Główna kategoria",
            "code": "Kod",
            "reusable": "Wielokrotnego użytku",
            "parameters": "Parametry",
            "price_per_item": "Cena za sztukę",
            "check_status": "Sprawdź status",
            "download": "Pobierz",
            "total_price": "Całkowita cena",
            "dpd_delivery_status": "Stan dostawy DPD",
            "date": "Data",
            "products_price": "Cena towarow",
            "notes": "Uwagi",
            "optionally": "Opcjonalnie",
            "auto_slug_desc": "Jeśli pusty, zostanie wygenerowany na podstawie nazwy",
            "user_name": "Nazwa użytkownika",
            "rating": "Ocena",
            "print_dpd_labels": "Wydrukuj etykiety dpd",
            "brand_rating": "Ocena marek",
            "shop_products_rating": "Ocena towarów w sklepie",
            "orders_number": "Ilość zamówień",
            "revenue": "Przychody",
            "average_order_value_sl": "Średnia wartość <br> zamówienia",
            "unique_orders": "Unikalne zakupy",
            "conversion_rate_e-commerce_sl": "Współczynnik konwersji <br> e-commerce",
            "number_of_registered": "Ilość zarejestrowanych",
            "orders_number_from_unregistered_sl": "Ilość zamówień od niezarejestrowanych <br> użytkowników",
            "orders_number_from_registered_sl": "Ilość zamówień od zarejestrowanych <br> użytkowników",
            "user_registration_by_year": "Rejestracja użytkownikow według roku",
            "weight_and_dimensions": "Waga i wymiary",
            "height_from": "Wysokość od",
            "height_to": "Wysokość do",
            "working_on_site": "Praca na miejscu",
            "allowed_ips": "Dozwolone ip",
            "subject": "Temat",
            "select_color_and_mark_price": "Wybierz kolory i oznacz procent korekty ceny",
            "subcategories": "Podkategorie",
            "valuation_applies": "Wycena dotyczy",
            "box_cross-section": "Przekrój skrzynki",
            "chose_translation": "Wybierz tłumaczenie",
            "create_new_translation": "Utwórz nowe tłumaczenie",
            "delivery_price": "Koszt wysyłki",
            "new_paid_order": "Nowe zapłacone zamówienie",
            "status_changed": "Status changed",
            "status_changed_from": "Status zmieniony z \":status_from\" na \":status_to\"",
            "from_smb": "Od",
            "to_smb": "Do",
            "back_to_home_page": "Powrót do strony głównej",
            "no_any_reviews": "Obecnie nie ma żadnych opinii",
            "send_message": "Wyślij wiadomość",
            "information": "Informacja",
            "data_protection": "Ochrona danych",
            "legal_info": "Impressum",
            "follow_us_on": "Śledź nas na",
            "subscribe": "Subskrybuj",
            "newsletter": "Newsletter",
            "newsletter_desc": "Zapisz się do naszego newslettera, aby otrzymywać najnowsze artykuły i kody rabatowe do lookbooków bezpośrednio na swoją skrzynkę odbiorczą",
            "free_delivery_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "per_page": "Na stronę",
            "from_qty": "Od",
            "to_qty": "do",
            "sort_a_z": "A do Z",
            "sort_z_a": "Z do A",
            "sort_high_to_low": "Malejąco",
            "sort_low_to_high": "Rosnąco",
            "sort_by": "Sortuj według",
            "new_products_desc": "LOREM IPSUM DOLOR SIT AMET CONSECTETUR",
            "find_more": "Znajdź więcej",
            "nothing_to_pay": "Nie ma nic do zapłaty",
            "shop_cart_is_empty": "Kosz jest pusty",
            "view_cart": "Zobacz Koszyk",
            "gdpr": "RODO",
            "regulations": "Regulamin",
            "withdrawal_rights": "Widerrufsbelehrung",
            "coockies": "Cookies",
            "find_out_more": "Dowiedz się więcej",
            "discard_all": "Odrzuć wszystkie",
            "apply_all": "Zastosuj wszystkie",
            "control_your_privacy": "Kontroluj swoją prywatność",
            "cookie_statement": "Cookie Statement",
            "about_coockie": "O plikach cookie",
            "cookie_report": "Raport cookie",
            "provider": "Provider",
            "domain": "Domena",
            "storage_period": "Okres przechowywania",
            "year_s": "Rok(lata)",
            "hour_s": "Godzina(y)",
            "day_s": "Dzień(dni)",
            "targeting": "Targetowanie",
            "details": "Szczegóły",
            "main_page_foot_section_2_title": "DARMOWA INSTALACJA",
            "main_page_foot_section_2_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "main_page_foot_section_3_title": "GWARANTOWANY ZWROT PIENIĘDZY",
            "main_page_foot_section_3_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "transfer": "Transfer",
            "payment_failed": "Płatność nie powiodła się",
            "new_order": "Nowe zamówienie",
            "is_paid": "Jest płacone",
            "rolls_free_delivery_from": "Darmowa dostawa rolet od",
            "for_free_rolls_delivery": "Do darmowej wysyłki rolet",
            "gray": "Szary",
            "width": "Szerokość",
            "height": "Wysokość",
            "wood-like_colors_veneers": "Kolory drewnopodobne - okleiny",
            "color_code": "Kod koloru",
            "free_delivery_by_buying_configured_rolet": "Darmowa dostawa przy zakupie skonfigurowanej roli",
            "category_id": "Kategoria"
        },
        "admin": {
            "online": "Online",
            "login": "Login",
            "logout": "Logout",
            "setting": "Ustawienia",
            "name": "Nazwa",
            "username": "Użytkownik",
            "password": "Hasło",
            "password_confirmation": "Powtórz hasło",
            "remember_me": "Zapamiętaj mnie",
            "user_setting": "Ustawienia użytkownika",
            "avatar": "Avatar",
            "list": "Lista",
            "new": "Nowy",
            "create": "Utwórz",
            "delete": "Usuń",
            "remove": "Usuń",
            "edit": "Edytuj",
            "view": "Zobacz",
            "browse": "Przeglądaj",
            "reset": "Resetuj",
            "export": "Eksportuj",
            "batch_delete": "Usuń koszyk",
            "batch_disable": "Deaktywuj koszyk",
            "batch_update_price": "Aktualizuj wartość w koszyku",
            "save": "Zapisz",
            "refresh": "Odśwież",
            "order": "Sortuj",
            "expand": "Rozwiń",
            "collapse": "Zwiń",
            "filter": "Filtruj",
            "close": "Zamknij",
            "show": "Wyświetl",
            "entries": "wpisy",
            "captcha": "Captcha",
            "action": "Akcja",
            "title": "Tytuł",
            "description": "Opis",
            "back": "Wróć",
            "back_to_list": "Wróć do listy",
            "submit": "Wyślij",
            "menu": "Menu",
            "input": "Pole",
            "succeeded": "Sukces",
            "failed": "Błąd",
            "disable_confirm": "Czy na pewno wyłączyć ten element?",
            "update_confirm": "Czy na pewno zaktualizować?",
            "delete_confirm": "Czy na pewno chcesz usunąć?",
            "delete_succeeded": "Pomyślnie usunięto!",
            "delete_failed": "Usuwawnie nie powiodło się!",
            "disable_succeeded": "Wyłączono!",
            "disable_failed": "Nie wyłączono!",
            "update_succeeded": "Pomyślnie zmieniono!",
            "update_failed": "Nie zaktualizowano!",
            "save_succeeded": "Pomyślnie zapisano!",
            "refresh_succeeded": "Pomyślnie odświeżono!",
            "login_successful": "Pomyślnie zalogowano",
            "choose": "Wybierz",
            "choose_file": "Wybierz plik",
            "choose_image": "Wybierz obraz",
            "more": "Więcej",
            "deny": "Brak dostępu",
            "administrator": "Administrator",
            "roles": "Role",
            "permissions": "Uprawnienia",
            "slug": "skrót",
            "created_at": "Utworzono",
            "updated_at": "zmieniono",
            "alert": "Alarm",
            "parent_id": "Rodzic",
            "icon": "Ikona",
            "uri": "URI",
            "operation_log": "Dziennik operacji",
            "parent_select_error": "Wybór rodzica nie powiódł się",
            "role": "Role",
            "permission": "Permission",
            "route": "Route",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "all_methods_if_empty": "All methods if empty",
            "all": "All",
            "current_page": "Current page",
            "selected_rows": "Selected rows",
            "upload": "Upload",
            "new_folder": "New folder",
            "time": "Time",
            "size": "Size",
            "module_title": ":module module",
            "price_type_fixed": "Stała kwota",
            "price_type_percentage": "% kwoty",
            "pagination": {
                "range": "Wyświetlono :first do :last z wszystkich :total"
            },
            "http": {
                "method": "HTTP method",
                "path": "HTTP path"
            },
            "listbox": {
                "text_total": "Showing all {0}",
                "text_empty": "Empty list",
                "filtered": "{0} \/ {1}",
                "filter_clear": "Show all",
                "filter_placeholder": "Filter"
            },
            "module_description": {
                "index": "List",
                "show": "Show",
                "edit": "Edit",
                "create": "Create",
                "detail": "Detail"
            },
            "search": "Szukaj",
            "menu_titles": {
                "index": "Index",
                "site_menu": "Site menu(Kategorie)",
                "posts": "Posty",
                "pages": "Strony",
                "coupons": "Kupony",
                "products": "Produkty",
                "attributes": "Atrybuty",
                "options": "Opcje",
                "option_values": "Wartości opcji",
                "units": "Jednostki",
                "opinions": "Opinie",
                "configurators": "Konfiguratory",
                "configurator_types": "Rodzaje konfiguratorów",
                "external_rolls": "Rolety zewnętrzne",
                "roll_subtypes": "Podtypy rolety",
                "roll_boxes": "Skrzynki",
                "roll_shutter_arrangements": "Układ rolet",
                "roll_shields": "Pancerzy",
                "roll_shield_box_prices": "Ceny na skrzynkę z pancerzem",
                "roll_box_sizes": "Rozmiary Skrzynki",
                "roll_box_size_dependencies": "Rozmiary Skrzynki(Zależności)",
                "roll_drive_types": "Typy napędu",
                "roll_drive_types_options": "Typy napędu (opcje)",
                "roll_manage_sides": "Strony sterowania",
                "roll_rotation_types": "Typy wiercenia",
                "roll_elements_for_coloring": "Elementy do barwienia",
                "roll_engine_types": "Typy silników",
                "roll_engines": "Silniki",
                "colors": "Kolory",
                "plasterboard_types": "Typy listwy tynkowej",
                "color_groups": "Grupy kolorów",
                "adding_color_to_element": "Dodanie kolorów do elementów",
                "percent_setup_for_color_groups": "Ustawienie procentów dla grup kolorów",
                "additional_settings": "Dodatkowe ustawienia",
                "sliders": "Slidery",
                "site_users": "Użytkownicy",
                "settings": "Ustawienia",
                "adminpanel": "Panel administratora",
                "admin_users": "Użytkownicy administratorzy",
                "delivery_methods": "Metody dostawy",
                "logging": "Logowanie",
                "roles": "Role",
                "permissions": "Uprawnienia",
                "admin_menu_settings": "Ustawienia menu administratora",
                "operation_log": "Log operacji",
                "orders": "Zamówienia",
                "mailing": "Poczta",
                "order_returns": "Zwroty",
                "reports": "Raporty",
                "selling_report": "Raport sprzedaż",
                "client_statistic": "Statystyka klientów",
                "best_products_and_marks": "Najlepsze marki i produkty",
                "search_queries": "Zapytania wyszukiwania",
                "shop_reviews": "Recenzje sklepu",
                "app_translations": "Słowniki aplikacji",
                "client_translations": "Słowniki użytkownika",
                "engine_groups": "Grupy silników",
                "engine_group_remotes": "Piloty grupy silnika",
                "region_selling_statistic": "Statystyka sprzedaży w regionie",
                "configurator_previews": "Obrazy podglądu konfiguratora"
            }
        }
    },
    "en": {
        "validation": {
            "strong_password": {
                "length_and_upper_letter": "The :attribute must be at least :length characters and contain at least one uppercase character.",
                "length_and_number": "The :attribute must be at least :length characters and contain at least one number.",
                "length_and_special_char": "The :attribute must be at least :length characters and contain at least one special character.",
                "length_and_upper_char_and_number": "The :attribute must be at least :length characters and contain at least one uppercase character and one number.",
                "length_and_upper_char_and_special_char": "The :attribute must be at least :length characters and contain at least one uppercase character and one special character.",
                "length_and_upper_char_and_special_char_and_number": "The :attribute must be at least :length characters and contain at least one uppercase character, one number, and one special character.",
                "length": "The :attribute must be at least :length characters."
            },
            "accepted": "The :attribute must be accepted.",
            "accepted_if": "The :attribute must be accepted when :other is :value.",
            "active_url": "The :attribute is not a valid URL.",
            "after": "The :attribute must be a date after :date.",
            "after_or_equal": "The :attribute must be a date after or equal to :date.",
            "alpha": "The :attribute must only contain letters.",
            "alpha_dash": "The :attribute must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The :attribute must only contain letters and numbers.",
            "array": "The :attribute must be an array.",
            "ascii": "The :attribute must only contain single-byte alphanumeric characters and symbols.",
            "before": "The :attribute must be a date before :date.",
            "before_or_equal": "The :attribute must be a date before or equal to :date.",
            "boolean": "The :attribute field must be true or false.",
            "confirmed": "The :attribute confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The :attribute is not a valid date.",
            "date_equals": "The :attribute must be a date equal to :date.",
            "date_format": "The :attribute does not match the format :format.",
            "decimal": "The :attribute must have :decimal decimal places.",
            "declined": "The :attribute must be declined.",
            "declined_if": "The :attribute must be declined when :other is :value.",
            "different": "The :attribute and :other must be different.",
            "digits": "The :attribute must be :digits digits.",
            "digits_between": "The :attribute must be between :min and :max digits.",
            "dimensions": "The :attribute has invalid image dimensions.",
            "distinct": "The :attribute field has a duplicate value.",
            "doesnt_end_with": "The :attribute may not end with one of the following: :values.",
            "doesnt_start_with": "The :attribute may not start with one of the following: :values.",
            "email": "The :attribute must be a valid email address.",
            "ends_with": "The :attribute must end with one of the following: :values.",
            "enum": "The selected :attribute is invalid.",
            "exists": "The selected :attribute is invalid.",
            "file": "The :attribute must be a file.",
            "filled": "The :attribute field must have a value.",
            "image": "The :attribute must be an image.",
            "in": "The selected :attribute is invalid.",
            "in_array": "The :attribute field does not exist in :other.",
            "integer": "The :attribute must be an integer.",
            "ip": "The :attribute must be a valid IP address.",
            "ipv4": "The :attribute must be a valid IPv4 address.",
            "ipv6": "The :attribute must be a valid IPv6 address.",
            "json": "The :attribute must be a valid JSON string.",
            "lowercase": "The :attribute must be lowercase.",
            "mac_address": "The :attribute must be a valid MAC address.",
            "max_digits": "The :attribute must not have more than :max digits.",
            "mimes": "The :attribute must be a file of type: :values.",
            "mimetypes": "The :attribute must be a file of type: :values.",
            "min_digits": "The :attribute must have at least :min digits.",
            "multiple_of": "The :attribute must be a multiple of :value.",
            "not_in": "The selected :attribute is invalid.",
            "not_regex": "The :attribute format is invalid.",
            "numeric": "The :attribute must be a number.",
            "present": "The :attribute field must be present.",
            "prohibited": "The :attribute field is prohibited.",
            "prohibited_if": "The :attribute field is prohibited when :other is :value.",
            "prohibited_unless": "The :attribute field is prohibited unless :other is in :values.",
            "prohibits": "The :attribute field prohibits :other from being present.",
            "regex": "The :attribute format is invalid.",
            "required": "The :attribute field is required.",
            "required_array_keys": "The :attribute field must contain entries for: :values.",
            "required_if": "The :attribute field is required when :other is :value.",
            "required_if_accepted": "The :attribute field is required when :other is accepted.",
            "required_unless": "The :attribute field is required unless :other is in :values.",
            "required_with": "The :attribute field is required when :values is present.",
            "required_with_all": "The :attribute field is required when :values are present.",
            "required_without": "The :attribute field is required when :values is not present.",
            "required_without_all": "The :attribute field is required when none of :values are present.",
            "same": "The :attribute and :other must match.",
            "starts_with": "The :attribute must start with one of the following: :values.",
            "string": "The :attribute must be a string.",
            "timezone": "The :attribute must be a valid timezone.",
            "unique": "The :attribute has already been taken.",
            "uploaded": "The :attribute failed to upload.",
            "uppercase": "The :attribute must be uppercase.",
            "url": "The :attribute must be a valid URL.",
            "ulid": "The :attribute must be a valid ULID.",
            "uuid": "The :attribute must be a valid UUID.",
            "between": {
                "array": "The :attribute must have between :min and :max items.",
                "file": "The :attribute must be between :min and :max kilobytes.",
                "numeric": "The :attribute must be between :min and :max.",
                "string": "The :attribute must be between :min and :max characters."
            },
            "gt": {
                "array": "The :attribute must have more than :value items.",
                "file": "The :attribute must be greater than :value kilobytes.",
                "numeric": "The :attribute must be greater than :value.",
                "string": "The :attribute must be greater than :value characters."
            },
            "gte": {
                "array": "The :attribute must have :value items or more.",
                "file": "The :attribute must be greater than or equal to :value kilobytes.",
                "numeric": "The :attribute must be greater than or equal to :value.",
                "string": "The :attribute must be greater than or equal to :value characters."
            },
            "lt": {
                "array": "The :attribute must have less than :value items.",
                "file": "The :attribute must be less than :value kilobytes.",
                "numeric": "The :attribute must be less than :value.",
                "string": "The :attribute must be less than :value characters."
            },
            "lte": {
                "array": "The :attribute must not have more than :value items.",
                "file": "The :attribute must be less than or equal to :value kilobytes.",
                "numeric": "The :attribute must be less than or equal to :value.",
                "string": "The :attribute must be less than or equal to :value characters."
            },
            "max": {
                "array": "The :attribute must not have more than :max items.",
                "file": "The :attribute must not be greater than :max kilobytes.",
                "numeric": "The :attribute must not be greater than :max.",
                "string": "The :attribute must not be greater than :max characters."
            },
            "min": {
                "array": "The :attribute must have at least :min items.",
                "file": "The :attribute must be at least :min kilobytes.",
                "numeric": "The :attribute must be at least :min.",
                "string": "The :attribute must be at least :min characters."
            },
            "password": {
                "letters": "The :attribute must contain at least one letter.",
                "mixed": "The :attribute must contain at least one uppercase and one lowercase letter.",
                "numbers": "The :attribute must contain at least one number.",
                "symbols": "The :attribute must contain at least one symbol.",
                "uncompromised": "The given :attribute has appeared in a data leak. Please choose a different :attribute."
            },
            "size": {
                "array": "The :attribute must contain :size items.",
                "file": "The :attribute must be :size kilobytes.",
                "numeric": "The :attribute must be :size.",
                "string": "The :attribute must be :size characters."
            },
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "new1": "dfsd1",
            "new5": "dddfd"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;",
            "app": {
                "shown_num_items_from_num": "Test"
            }
        },
        "new": {
            "sending_message_in_queue": "Sending message in queue",
            "main_category_should_not_be_parent": "You cannot specify parent category",
            "confirmation_add_to_shop_cart": "Are you sure you want to add product to shopping cart?",
            "hello": "Hello",
            "thanks": "Thanks",
            "regards": "Regards",
            "added_to_shopcart": "Successfully added to shopping cart!",
            "successfully_deleted": "Successfully deleted",
            "confirm_order_deleting": "You have decided to delete the order. Are you continue?",
            "payment_order_made_message": "Payment was made successfully. Thank you for your order",
            "reset_pass_desc": "You are receiving this email because we received a password reset request for your account",
            "reset_pass_trouble_info": "If you’re having trouble clicking the „Reset password” button, copy and paste the URL below\ninto your web browser",
            "mail_successfully_send": "Message was sent successfully",
            "mail_not_send": "The message has not been sent",
            "new_question": "New question"
        },
        "messages": {
            "sending_message_in_queue": "Sending message in queue",
            "main_category_should_not_be_parent": "You cannot specify parent category",
            "confirmation_add_to_shop_cart": "Are you sure you want to add product to shopping cart?",
            "hello": "Hello",
            "thanks": "Thanks",
            "regards": "Regards",
            "added_to_shopcart": "Successfully added to shopping cart!",
            "successfully_deleted": "Successfully deleted",
            "confirm_order_deleting": "You have decided to delete the order. Are you continue?",
            "payment_order_made_message": "Payment was made successfully. Thank you for your order",
            "reset_pass_desc": "You are receiving this email because we received a password reset request for your account",
            "reset_pass_trouble_info": "If you’re having trouble clicking the „Reset password” button, copy and paste the URL below\n",
            "mail_successfully_send": "Message was sent successfully",
            "mail_not_send": "The message has not been sent",
            "new_question": "New question",
            "customize_cookie_message": "You can configure cookies that are convenient for you, except for essential ones, the cancellation of which may affect the operation of the site",
            "required_cookie_fields_message": "These cookie fields are necessary for the website to function and cannot be turned off. Can you set your browser to block these cookies, but in that case it is possible that some sections of the site will stop working",
            "targeting_cookie_fields_message": "These cookie fields are set through our website by our partners. They can be used to collect data about your interests, pages visited and traffic sources in order to measure and improve our website",
            "cookie_apply_description": "By clicking the ':apply' button, you consent to the processing of cookies in accordance with :policy_link",
            "cookie_policy_link": "Cookie policy",
            "cookie_common_message": "We may process your personal data and use technologies such as cookies to provide services and keep statistics. You can change your choice at any time by clicking on \"Privacy Settings\" in the footer of the page and\/or in the browser settings. By using the website, you agree to the terms of personal data processing contained in the privacy policy.",
            "cookie_policy_desc": "This cookie is used to remember your cookie consent preferences.",
            "cookie_xsrf-token_desc": "This cookie is written to help with site security in preventing Cross-Site Request Forgery attacks",
            "cookie_laravel_session_desc": "Internally application uses :coockie_name to identify a session instance for a user",
            "targeting_coockie_desc": "Targeting cookies are used to identify visitors between different websites, e.g. content partners, advertising networks. These cookies may be used by companies to create profiles of user's interests or to display relevant advertisements on other websites.",
            "coockie_common_info": "Cookies are small text files that are stored on your computer by the websites you browse. Websites use cookies to help users navigate efficiently and perform certain functions. Cookies that are necessary for the website to function properly do not require your consent. All other cookies require consent before setting your browser to handle them.",
            "sending_notifications_acceptation": "I allow information to be sent via email",
            "using_private_data_acceptation": "I allow the use of my data",
            "read_and_accepted": "I have read and accepted",
            "policy_acceptation": "Ich erkläre, dass ich die <a class=\"acceptation-link\" href=\":policy_route\" target=\"_blank\" >:policy_link<\/a> gelesen habe. Ich erkläre, dass ich die <a class=\"acceptation-link\" href=\":acceptation_deny_route\" target=\"_blank\" >:acceptation_deny_link<\/a> gelesen habe",
            "updated_successfully": "Updated successfully",
            "deleted_successfully": "Deleted successfully",
            "created_successfully": "Created successfully",
            "added_successfully": "Added successfully",
            "imported_successfully": "Imported successfully",
            "something_was_wrong": "Oops. Something was wrong",
            "slug_exists_in_locale": "Such slug already exists in this locale",
            "order_without_parcels": "Order does not has parcels",
            "no_parcel_weight": "Parcel weight was not specified",
            "parcels_not_chosen": "Any parcels was not chosen",
            "thanks_for_order": "Thanks for order",
            "we_are_sorry": "We're sorry",
            "copyright": "Copyright © :current_year. Design by TivaTheme. All Rights Reserved",
            "to_big_size": "Too big size",
            "def_color_was_not_set": "Default color for box was not set or price (element - color group) was not set",
            "rol_sizes_error": "Rol sizes error",
            "shield\/box_price_was_not_set": "Shield box price for chosen sizes was not set",
            "such_box_doesnt_exist": "Such box does not exist",
            "such_structure_doesnt_exist": "Such structure does not exist",
            "such_shield_doesnt_exist": "Such shield does not exist",
            "such_box_seria_doesnt_exist": "Such box seria does not exist",
            "such_box_sizes_not available": "Such box sizes not available or was not set",
            "default_color_for_shield_was_not_set": "Default color for shield was not set",
            "default_color_for_bottom_band_was_not_set": "Default color for bottom band was not set",
            "confirmation_transfer_payment": "Confirmation transfer payment",
            "transfer_payment_request_is_processing": "Tansfer payment request is processing"
        },
        "configurators": {
            "box": "Box",
            "boxes": "Boxes",
            "system": "Structure",
            "hole_dimensions": "Hole dimensions",
            "holes_dimensions": "Hole dimensions",
            "shield_type": "Shield type",
            "shield_color": "Shield color",
            "bottom_band_color": "Bottom band color",
            "selected_structure_size": "Selected structure size",
            "box_color": "Box color",
            "guide_color": "Guide color",
            "guide_rotation": "Guide rotation",
            "manage_type": "Manage type",
            "roll_managing": "Roll managing",
            "manage_side": "Manage side",
            "mosquito_net": "Mosquito net",
            "width": "Width",
            "height": "Height",
            "qty": "Qty",
            "shield_not_available": "Shield not available for chosen sizes. Please change size",
            "cm": "cm",
            "hatch_color": "Inspection hatch\/plaster strip color",
            "plasterboard_type": "Plasterboard type",
            "box_sizes_not_available_or_not_chosen": "Box sizes not available or not selected",
            "manage_type_not_available": "Given dimensions make it impossible to produce such a roller blind",
            "remotes": "Remotes",
            "engines": "Engines",
            "remote": "Remote control",
            "shields": "Shields",
            "shield": "Shield",
            "shield_sizes": "Shield sizes",
            "includes_mosquito_net": "Includes mosquito net",
            "show_wood_colors": "Show wood colors",
            "prices_of_box_with_shield": "Prices of box with shield",
            "box_sizes": "Box_sizes",
            "box_size": "Box_size",
            "engine_type": "Engine type",
            "roll_type": "Roll type",
            "roll_subtype": "Roll subtype",
            "roll_subtypes": "Roll subtypes",
            "percent_per_color_group": "Percent per color_group",
            "elements_for_coloring": "Elements for coloring",
            "engine_group_remotes": "Engine group remotes",
            "engine_types": "Engine types",
            "rotation_types": "Rotation types",
            "manage_sides": "Manage sides",
            "drive_types_options": "Drive types (options)",
            "plasterboard_types": "Plasterboard types",
            "configurator_images": "Configurator images",
            "roll_structure": "Roll structure",
            "pairs_number_of_size_fields": "Pairs number of size fields",
            "colors_related_to_box": "Colors related to box",
            "colors_related_to_shield": "Colors related to shield",
            "colors_related_to_manual_driving": "Colors related to manual driving",
            "colors_sl_elements": "Colors\/Elements",
            "clinet_file": "Klapa rewizyjna\/listwa tynkowa"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The provided password is incorrect.",
            "throttle": "Too many login attempts. Please try again in :seconds seconds.",
            "verify_your_email": "Verify your email",
            "check_your_email": "Please, check your mail",
            "if_didnt_get_link": "If you have not got link, please press to",
            "get_another_link": "get another link",
            "verify_click_text": "Click the button below to verify your email address",
            "verify_email": "Verify",
            "mail_click_trouble": "If you’re having trouble clicking the \":actionText\" button, copy and paste the URL below\n",
            "create_account": "Create account",
            "log_in": "Auth login",
            "are_you_forgot_your_pass": "Have you forgot password?",
            "reset_password": "Reset password"
        },
        "attribut": {
            "title": "Attributes for products",
            "description": {
                "index": "List",
                "show": "Item",
                "edit": "Edit",
                "create": "Create"
            }
        },
        "app": {
            "nav": {
                "howToMeasure": "jak-mierzyc",
                "gdpr": "rodo",
                "aboutUs": "o-nas",
                "contacts": "kontakt",
                "howToMount": "jak_montowac"
            },
            "status": "Status",
            "status_active": "Active",
            "status_inactive": "Inactive",
            "yes": "Yes",
            "no": "No",
            "locale": "Locale",
            "new": "New",
            "admin_panel_header": "Admin panel",
            "home_page": "Main page",
            "categories": "Categories",
            "new_products": "New products",
            "products_available": "Available",
            "catalog": "Catalog",
            "buy_together": "Buy together",
            "attributes": "Atrybuty",
            "options": "Options",
            "paired_products": "Paired products",
            "related_products": "Related products",
            "login": "Login",
            "registration": "Registration",
            "shop_cart": "Shopping cart",
            "password": "Password",
            "email": "Email",
            "first_name": "First name",
            "last_name": "Last name",
            "create": "Create",
            "password_confirmation": "Password confirmation",
            "phone": "Phone",
            "firm_name": "Firm name",
            "new_password": "New password",
            "nip": "NIP",
            "save": "Save",
            "edit": "Edit",
            "menu": "Menu",
            "edit_profile": "Edit profile",
            "orders_history": "Orders history",
            "my_account": "My account",
            "account": "Account",
            "logout": "Log out",
            "address": "Address",
            "country": "Country",
            "region": "Region",
            "city": "City",
            "street": "Street",
            "post_code": "Post code",
            "purchase": "Purchase",
            "private_person": "Private person",
            "account_type": "Account_type",
            "firm": "Firm",
            "personal_information": "Personal information",
            "rol_rotation_a": "Rotation guides A",
            "rol_rotation_b": "Rotation guides B",
            "color": "Color",
            "products": "Products",
            "status_new": "New",
            "status_in_progress": "In progress",
            "status_rejected": "Rejected",
            "status_taken": "Taken",
            "status_is_send": "Sent",
            "status_returned": "Returned",
            "status_confirmed": "Confirmed",
            "send": "Send",
            "message": "Message",
            "discount": "Discount",
            "discount_in_percent": "Discount in percent",
            "date_from": "From",
            "date_to": "To",
            "is_active": "Is active",
            "coupon": "Coupon",
            "search": "Search",
            "search_descr": "FIND YOUR PRODUCT WITH FAST SEARCH. ENTER SOME KEYWORD SUCH AS DRESS, SHIRTS, SHOES ETC. OR CAN SEARCH BY PRODUCT SKU.",
            "value_is_translatable": "To translate",
            "select": "Select",
            "description": "Description",
            "reviews": "Reviews",
            "free_delivery_from": "FREE DELIVERY FROM",
            "full_name": "Full name",
            "submit": "Submit",
            "comment": "Comment",
            "your_rating": "Your rating",
            "to_cart": "Add to cart",
            "shop_reviews": "Shop reviews",
            "contact": "Shop reviews",
            "unit_g": "gm",
            "unit_mm": "mm",
            "unit_m2": "m2",
            "unit_kg": "kg",
            "unit_cm": "cm",
            "unit_pcs": "pcs",
            "send_reset_link": "Send reset link",
            "password_reset": "Password reset",
            "back_to_login": "Back to login",
            "password_resetting": "Password resetting",
            "agreement_payment_menu": "AGB",
            "contact_us": "Contact us",
            "engine": "Engine-2",
            "type": "Type",
            "mosquito_net": "Mosquito net",
            "open_shopcart": "Open shopcart",
            "continue": "Continue",
            "add": "Add",
            "cancel": "Cancel",
            "download_pdf": "Download pdf",
            "offer": "Offer",
            "data": "Data",
            "delivery": "Delivery",
            "paid": "Paid",
            "total": "Total",
            "quantity_short": "QTY",
            "review": "Review",
            "read_reviews": "Read reviews",
            "write_review": "Write review",
            "items_count_in_shopcart": "There are :quantity item(s) in your cart",
            "total_products": "Total products",
            "total_delivery": "Total delivery",
            "for_free_delivery": "For free delivery",
            "free": "Free",
            "delete": "Delete",
            "main_data": "Main data",
            "returning_form": "Returning form",
            "order_number": "Order number",
            "returning_reason": "Returning reason",
            "returning": "Returning",
            "order": "Order",
            "free_delivery": "Free delivery",
            "id": "ID",
            "name": "Name",
            "name_short": "Name short",
            "title": "Title",
            "meta_title": "Meta title",
            "meta_description": "Meta description",
            "parent_id": "Parent",
            "icon": "Icon",
            "uri": "Uri",
            "created_at": "Created At",
            "updated_at": "Updated At",
            "discount_placeholder": "Enter Discount",
            "discount_helper": "Discount in %",
            "remember_token": "Remember Token",
            "email_verified_at": "Email verified at",
            "main_category_id": "Main category",
            "main_category_id_helper": "Specify the menu item for the product",
            "category": "Category",
            "price": "Price",
            "price_from": "Price from",
            "price_placeholder": "Enter price",
            "price_helper": "Price",
            "img": "Image",
            "image": "Image",
            "images": "Images",
            "url": "URL",
            "video_url": "URL to video",
            "quantity": "Quantity",
            "group": "Group",
            "calculation_type": "Calculation type",
            "is_available": "Enabled",
            "deliverymethods_name": "Delivery method name",
            "deliverymethods_price": "Shipping cost",
            "productsfixed_main_image": "Main image",
            "productsfixed_images": "Additional images",
            "options_helper": "Select the required parameters from the list and enter the value.",
            "slug": "Slug",
            "slug_help": "The inscription will be visible in the address bar. Generated automatically or can be set manually",
            "slider_alt_helper": "A message will be displayed if photos are disabled",
            "picture": "Picture",
            "text": "text",
            "content": "content",
            "product": "Product to display",
            "product_name_helper": "Name of the product that will be displayed when clicking on the menu specified above",
            "value": "Value",
            "bound_categories": "Bound categories",
            "available": "Available",
            "producer": "Producer",
            "vat": "Vat",
            "price_net": "Net price",
            "price_gross": "Gross price",
            "new1": "sds",
            "months": {
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December"
            },
            "acceptation": "Acceptations",
            "sender": "Sender",
            "recipient": "Recipient",
            "house_number": "House number",
            "apply": "Apply",
            "close": "Close",
            "weight": "Weight",
            "boxes_for_sending": "Parcels for sending",
            "availability": "Availability",
            "many_available": "Large amount",
            "little_available": "Small amount",
            "currently_not_available": "Currently not available",
            "customize": "Customize",
            "back": "Back",
            "required_fields_cookie": "Required files cookie",
            "targeting_fields_cookie": "Targeting fields cookie",
            "cookie_policy": "Cookie policy",
            "shown_num_items_from_num": "Shown :shown_num items from :total_num",
            "others": "Others",
            "configure": "Configure",
            "name_from_dictionary": "Translation from dictionary",
            "first_name_and_last_name": "First name and last name",
            "order_as_guest": "Order as a guest",
            "another_delivery_address": "Another delivery address",
            "payment": "Payment",
            "sending_notifications_acceptation": "I allow information to be sent via email",
            "using_private_data_acceptation": "I allow the use of my data",
            "read_and_accepted": "I have read and accepted",
            "seller_notes": "Note for seller",
            "about_us": "About us",
            "blog": "Blog",
            "read_more": "Read more",
            "files": "Files",
            "delivery_address": "Delivery address",
            "chosen_by_default": "Chosen by default",
            "delivery_method": "Delivery method",
            "price_per_kg": "Price per kg",
            "agreement_to_pay_for_order": "Agreement to pay",
            "import": "Import",
            "engine_groups": "Engine groups",
            "additional_settings": "Additional settings",
            "subtype": "Subtype",
            "by_default": "By default",
            "size": "Size",
            "write_price": "Write price",
            "max_height": "Max height",
            "min_height": "Min height",
            "max_width": "Max width",
            "min_width": "Min width",
            "colors": "Colors",
            "editing": "Editing",
            "deleting": "Deleting",
            "creating": "Creating",
            "updating": "Updating",
            "color_group": "Color group",
            "color_groups": "Color groups",
            "color_subgroup": "Color subgroup",
            "color_subgroups": "Color subgroups",
            "create_new": "Create new",
            "percent": "Percent",
            "element": "Element",
            "model": "Model",
            "serial_number": "Serial number",
            "calculation_factor": "Calculation factor",
            "example": "Example",
            "example_for": "Example for",
            "sizes": "Sizes",
            "colors_are_required": "Colors are required",
            "orders": "Orders",
            "customer": "Customer",
            "max_square": "Max square",
            "weight_per": "Weight per",
            "allowed_size": "Allowed size",
            "main_category": "Main category",
            "code": "Code",
            "reusable": "Reusable",
            "parameters": "Parameters",
            "price_per_item": "Price per item",
            "check_status": "Check status",
            "download": "Download",
            "total_price": "Total price",
            "dpd_delivery_status": "Dpd delivery status",
            "date": "Date",
            "products_price": "Products price",
            "notes": "Notes",
            "optionally": "Optionally",
            "auto_slug_desc": "If empty will be generated from name",
            "user_name": "User name",
            "rating": "Rating",
            "print_dpd_labels": "print_dpd_labels",
            "brand_rating": "Brand rating",
            "shop_products_rating": "Shop products rating",
            "orders_number": "Orders number",
            "revenue": "Revenue",
            "average_order_value_sl": "Average order <br> value",
            "unique_orders": "Unique orders",
            "conversion_rate_e-commerce_sl": "Conversion rate <br> e-commerce",
            "number_of_registered": "Number of registered",
            "orders_number_from_unregistered_sl": "Orders number from <br> unregistered users",
            "orders_number_from_registered_sl": "Orders number from <br> registered users",
            "user_registration_by_year": "User registration by year",
            "weight_and_dimensions": "Weight and dimensions",
            "height_from": "Height from",
            "height_to": "Height to",
            "working_on_site": "Working on site",
            "allowed_ips": "Allowed ip",
            "subject": "Subject",
            "select_color_and_mark_price": "Select the colors and mark the price adjustment percentage",
            "subcategories": "Subcategories",
            "valuation_applies": "Valuation applies",
            "box_cross-section": "Box cross-section",
            "chose_translation": "Chose translation",
            "create_new_translation": "Create new translation",
            "delivery_price": "Delivery price",
            "new_paid_order": "New paid order",
            "status_changed": "Status changed",
            "status_changed_from": "Status changed from \":status_from\" to \":status_to\"",
            "from_smb": "From",
            "to_smb": "To",
            "back_to_home_page": "Back to homepage",
            "no_any_reviews": "Obecnie nie ma żadnych opinii",
            "send_message": "Send message",
            "information": "Information",
            "data_protection": "Data protection",
            "legal_info": "Impressum",
            "follow_us_on": "Follow Us On",
            "subscribe": "Subscribe",
            "newsletter": "Newsletter",
            "newsletter_desc": "Sign up to our newsletter to get the latest articles, lookbooks voucher codes direct to your inbox",
            "free_delivery_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "per_page": "Per page",
            "from_qty": "From",
            "to_qty": "To",
            "sort_a_z": "A to Z",
            "sort_z_a": "Z to A",
            "sort_high_to_low": "Low to high",
            "sort_low_to_high": "High to low",
            "sort_by": "Sort by",
            "new_products_desc": "LOREM IPSUM DOLOR SIT AMET CONSECTETUR",
            "find_more": "Find more",
            "nothing_to_pay": "Nothing to pay",
            "shop_cart_is_empty": "Shopcart is empty",
            "view_cart": "View Cart",
            "gdpr": "GDPR",
            "regulations": "Regulations",
            "withdrawal_rights": "Widerrufsbelehrung",
            "coockies": "Cookies",
            "find_out_more": "Find out more",
            "discard_all": "Discard all",
            "apply_all": "Apply all",
            "control_your_privacy": "Control your privacy",
            "cookie_statement": "Cookie Statement",
            "about_coockie": "About cookie",
            "cookie_report": "Cookie report",
            "provider": "Provider",
            "domain": "Domain",
            "storage_period": "Storage period",
            "year_s": "Year(s)",
            "hour_s": "Hours(s)",
            "day_s": "Day(s)",
            "targeting": "Targeting",
            "details": "Details",
            "main_page_foot_section_2_title": "FREE INSTALLATION",
            "main_page_foot_section_2_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "main_page_foot_section_3_title": "MONEY BACK GUARANTEED",
            "main_page_foot_section_3_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "transfer": "Transfer",
            "payment_failed": "Payment failed",
            "new_order": "New order",
            "is_paid": "Is paid",
            "rolls_free_delivery_from": "Free delivery of rolls from",
            "for_free_rolls_delivery": "For free rolls delivery",
            "gray": "Gray",
            "width": "Width",
            "height": "Height",
            "wood-like_colors_veneers": "Wood-like colors - veneers",
            "color_code": "Color code",
            "free_delivery_by_buying_configured_rolet": "Free delivery if to buy configured rolet",
            "category_id": "Category"
        },
        "admin": {
            "online": "Online",
            "login": "Login",
            "logout": "Logout",
            "setting": "Settings",
            "name": "Name",
            "username": "Username",
            "password": "Password",
            "password_confirmation": "Password confirmation",
            "remember_me": "Remember me",
            "user_setting": "User setting",
            "avatar": "Avatar",
            "list": "List",
            "new": "New",
            "create": "Create",
            "delete": "Delete",
            "remove": "Remove",
            "edit": "Edit",
            "view": "View",
            "browse": "Browse",
            "reset": "Reset",
            "export": "Export",
            "batch_delete": "Batch delete",
            "batch_disable": "Batch disable",
            "batch_update_price": "Batch price update",
            "save": "Save",
            "refresh": "Refresh",
            "order": "Order",
            "expand": "Expand",
            "collapse": "Collapse",
            "filter": "Filter",
            "close": "Close",
            "show": "Show",
            "entries": "entries",
            "captcha": "Captcha",
            "action": "Action",
            "title": "Title",
            "description": "Description",
            "back": "Back",
            "back_to_list": "Back to List",
            "submit": "Submit",
            "menu": "Menu",
            "input": "Input",
            "succeeded": "Succeeded",
            "failed": "Failed",
            "disable_confirm": "Are you sure to disable this item ?",
            "update_confirm": "Are you sure to update this item?",
            "delete_confirm": "Are you sure to delete this item ?",
            "delete_succeeded": "Delete succeeded!",
            "delete_failed": "Delete failed!",
            "disable_succeeded": "Disable succeeded!",
            "disable_failed": "Disable failed!",
            "update_succeeded": "Update succeeded!",
            "update_failed": "Update failed!",
            "save_succeeded": "Save succeeded!",
            "refresh_succeeded": "Refresh succeeded !",
            "login_successful": "Login successful",
            "choose": "Choose",
            "choose_file": "Select file",
            "choose_image": "Select image",
            "more": "More",
            "deny": "Permission denied",
            "administrator": "Administrator",
            "roles": "Roles",
            "permissions": "Permissions",
            "slug": "Slug",
            "created_at": "Created At",
            "updated_at": "Updated At",
            "alert": "Alert",
            "parent_id": "Parent",
            "icon": "Icon",
            "uri": "URI",
            "operation_log": "Operation log",
            "parent_select_error": "Parent select error",
            "role": "Role",
            "permission": "Permission",
            "route": "Route",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "all_methods_if_empty": "All methods if empty",
            "all": "All",
            "current_page": "Current page",
            "selected_rows": "Selected rows",
            "upload": "Upload",
            "new_folder": "New folder",
            "time": "Time",
            "size": "Size",
            "module_title": ":module module",
            "price_type_fixed": "Fixed amount",
            "price_type_percentage": "% amount",
            "pagination": {
                "range": "Showing :first to :last of :total entries"
            },
            "http": {
                "method": "HTTP method",
                "path": "HTTP path"
            },
            "listbox": {
                "text_total": "Showing all {0}",
                "text_empty": "Empty list",
                "filtered": "{0} \/ {1}",
                "filter_clear": "Show all",
                "filter_placeholder": "Filter"
            },
            "module_description": {
                "index": "List",
                "show": "Show",
                "edit": "Edit",
                "create": "Create",
                "detail": "Detail"
            },
            "search": "Search 1",
            "menu_titles": {
                "index": "Index",
                "site_menu": "Site menu(Categories)",
                "posts": "Posts",
                "pages": "Pages",
                "coupons": "Coupons",
                "products": "Products",
                "attributes": "Attributes",
                "options": "Options",
                "option_values": "Option values",
                "units": "Units",
                "opinions": "Opinions",
                "configurators": "Configurators",
                "configurator_types": "Configurator types",
                "external_rolls": "External rolls",
                "roll_subtypes": "Subbtype rolls",
                "roll_boxes": "Boxes",
                "roll_shutter_arrangements": "Shutter arrangements",
                "roll_shields": "Shields",
                "roll_shield_box_prices": "Shield-box prices",
                "roll_box_sizes": "Box sizes",
                "roll_box_size_dependencies": "Box sizes(dependencies)",
                "roll_drive_types": "Drive types",
                "roll_drive_types_options": "Drive types (options)",
                "roll_manage_sides": "Manage sides",
                "roll_rotation_types": "Rotation types",
                "roll_elements_for_coloring": "Elements for coloring",
                "roll_engine_types": "Engine types",
                "roll_engines": "Engines",
                "colors": "Colors",
                "plasterboard_types": "Plasterboard types",
                "color_groups": "Color groups",
                "adding_color_to_element": "Assigning color to element",
                "percent_setup_for_color_groups": "Percent setup for color groups",
                "additional_settings": "Additional settings",
                "sliders": "Slides",
                "site_users": "Site users",
                "settings": "Settings",
                "adminpanel": "Adminpanel",
                "admin_users": "Adminpanel users",
                "delivery_methods": "Delivery methods",
                "logging": "Logging",
                "roles": "Roles",
                "permissions": "Permissions",
                "admin_menu_settings": "Admin menu settings",
                "operation_log": "Operation log",
                "orders": "Orders",
                "mailing": "Mailing",
                "order_returns": "Order returns",
                "reports": "Reports",
                "selling_report": "Selling report",
                "client_statistic": "Client statistic",
                "best_products_and_marks": "Best products and marks",
                "search_queries": "Search queries",
                "shop_reviews": "Shop reviews",
                "app_translations": "App translations",
                "client_translations": "Client translations",
                "engine_groups": "Engine groups",
                "engine_group_remotes": "Engine group remotes",
                "region_selling_statistic": "Region selling statistic",
                "configurator_previews": "Configurator preview images"
            }
        }
    },
    "de": {
        "validation": {
            "strong_password": {
                "length_and_upper_letter": "The :attribute must be at least :length characters and contain at least one uppercase character.",
                "length_and_number": "The :attribute must be at least :length characters and contain at least one number.",
                "length_and_special_char": "The :attribute must be at least :length characters and contain at least one special character.",
                "length_and_upper_char_and_number": "The :attribute must be at least :length characters and contain at least one uppercase character and one number.",
                "length_and_upper_char_and_special_char": "The :attribute must be at least :length characters and contain at least one uppercase character and one special character.",
                "length_and_upper_char_and_special_char_and_number": "The :attribute must be at least :length characters and contain at least one uppercase character, one number, and one special character.",
                "length": "The :attribute must be at least :length characters."
            },
            "accepted": "The :attribute must be accepted.",
            "accepted_if": "The :attribute must be accepted when :other is :value.",
            "active_url": "The :attribute is not a valid URL.",
            "after": "The :attribute must be a date after :date.",
            "after_or_equal": "The :attribute must be a date after or equal to :date.",
            "alpha": "The :attribute must only contain letters.",
            "alpha_dash": "The :attribute must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The :attribute must only contain letters and numbers.",
            "array": "The :attribute must be an array.",
            "ascii": "The :attribute must only contain single-byte alphanumeric characters and symbols.",
            "before": "The :attribute must be a date before :date.",
            "before_or_equal": "The :attribute must be a date before or equal to :date.",
            "boolean": "The :attribute field must be true or false.",
            "confirmed": "The :attribute confirmation does not match.",
            "current_password": "The password is incorrect.",
            "date": "The :attribute is not a valid date.",
            "date_equals": "The :attribute must be a date equal to :date.",
            "date_format": "The :attribute does not match the format :format.",
            "decimal": "The :attribute must have :decimal decimal places.",
            "declined": "The :attribute must be declined.",
            "declined_if": "The :attribute must be declined when :other is :value.",
            "different": "The :attribute and :other must be different.",
            "digits": "The :attribute must be :digits digits.",
            "digits_between": "The :attribute must be between :min and :max digits.",
            "dimensions": "The :attribute has invalid image dimensions.",
            "distinct": "The :attribute field has a duplicate value.",
            "doesnt_end_with": "The :attribute may not end with one of the following: :values.",
            "doesnt_start_with": "The :attribute may not start with one of the following: :values.",
            "email": "The :attribute must be a valid email address.",
            "ends_with": "The :attribute must end with one of the following: :values.",
            "enum": "The selected :attribute is invalid.",
            "exists": "The selected :attribute is invalid.",
            "file": "The :attribute must be a file.",
            "filled": "The :attribute field must have a value.",
            "image": "The :attribute must be an image.",
            "in": "The selected :attribute is invalid.",
            "in_array": "The :attribute field does not exist in :other.",
            "integer": "The :attribute must be an integer.",
            "ip": "The :attribute must be a valid IP address.",
            "ipv4": "The :attribute must be a valid IPv4 address.",
            "ipv6": "The :attribute must be a valid IPv6 address.",
            "json": "The :attribute must be a valid JSON string.",
            "lowercase": "The :attribute must be lowercase.",
            "mac_address": "The :attribute must be a valid MAC address.",
            "max_digits": "The :attribute must not have more than :max digits.",
            "mimes": "The :attribute must be a file of type: :values.",
            "mimetypes": "The :attribute must be a file of type: :values.",
            "min_digits": "The :attribute must have at least :min digits.",
            "multiple_of": "The :attribute must be a multiple of :value.",
            "not_in": "The selected :attribute is invalid.",
            "not_regex": "The :attribute format is invalid.",
            "numeric": "The :attribute must be a number.",
            "present": "The :attribute field must be present.",
            "prohibited": "The :attribute field is prohibited.",
            "prohibited_if": "The :attribute field is prohibited when :other is :value.",
            "prohibited_unless": "The :attribute field is prohibited unless :other is in :values.",
            "prohibits": "The :attribute field prohibits :other from being present.",
            "regex": "The :attribute format is invalid.",
            "required": "The :attribute field is required.",
            "required_array_keys": "The :attribute field must contain entries for: :values.",
            "required_if": "The :attribute field is required when :other is :value.",
            "required_if_accepted": "The :attribute field is required when :other is accepted.",
            "required_unless": "The :attribute field is required unless :other is in :values.",
            "required_with": "The :attribute field is required when :values is present.",
            "required_with_all": "The :attribute field is required when :values are present.",
            "required_without": "The :attribute field is required when :values is not present.",
            "required_without_all": "The :attribute field is required when none of :values are present.",
            "same": "The :attribute and :other must match.",
            "starts_with": "The :attribute must start with one of the following: :values.",
            "string": "The :attribute must be a string.",
            "timezone": "The :attribute must be a valid timezone.",
            "unique": "The :attribute has already been taken.",
            "uploaded": "The :attribute failed to upload.",
            "uppercase": "The :attribute must be uppercase.",
            "url": "The :attribute must be a valid URL.",
            "ulid": "The :attribute must be a valid ULID.",
            "uuid": "The :attribute must be a valid UUID.",
            "between": {
                "array": "The :attribute must have between :min and :max items.",
                "file": "The :attribute must be between :min and :max kilobytes.",
                "numeric": "The :attribute must be between :min and :max.",
                "string": "The :attribute must be between :min and :max characters."
            },
            "gt": {
                "array": "The :attribute must have more than :value items.",
                "file": "The :attribute must be greater than :value kilobytes.",
                "numeric": "The :attribute must be greater than :value.",
                "string": "The :attribute must be greater than :value characters."
            },
            "gte": {
                "array": "The :attribute must have :value items or more.",
                "file": "The :attribute must be greater than or equal to :value kilobytes.",
                "numeric": "The :attribute must be greater than or equal to :value.",
                "string": "The :attribute must be greater than or equal to :value characters."
            },
            "lt": {
                "array": "The :attribute must have less than :value items.",
                "file": "The :attribute must be less than :value kilobytes.",
                "numeric": "The :attribute must be less than :value.",
                "string": "The :attribute must be less than :value characters."
            },
            "lte": {
                "array": "The :attribute must not have more than :value items.",
                "file": "The :attribute must be less than or equal to :value kilobytes.",
                "numeric": "The :attribute must be less than or equal to :value.",
                "string": "The :attribute must be less than or equal to :value characters."
            },
            "max": {
                "array": "The :attribute must not have more than :max items.",
                "file": "The :attribute must not be greater than :max kilobytes.",
                "numeric": "The :attribute must not be greater than :max.",
                "string": "The :attribute must not be greater than :max characters."
            },
            "min": {
                "array": "The :attribute must have at least :min items.",
                "file": "The :attribute must be at least :min kilobytes.",
                "numeric": "The :attribute must be at least :min.",
                "string": "The :attribute must be at least :min characters."
            },
            "password": {
                "letters": "The :attribute must contain at least one letter.",
                "mixed": "The :attribute must contain at least one uppercase and one lowercase letter.",
                "numbers": "The :attribute must contain at least one number.",
                "symbols": "The :attribute must contain at least one symbol.",
                "uncompromised": "The given :attribute has appeared in a data leak. Please choose a different :attribute."
            },
            "size": {
                "array": "The :attribute must contain :size items.",
                "file": "The :attribute must be :size kilobytes.",
                "numeric": "The :attribute must be :size.",
                "string": "The :attribute must be :size characters."
            },
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "new1": "dfsd1",
            "new5": "dddfd"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have emailed your password reset link!",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;",
            "app": {
                "shown_num_items_from_num": "Test"
            }
        },
        "messages": {
            "sending_message_in_queue": "Sending message in queue",
            "main_category_should_not_be_parent": "You cannot specify parent category",
            "confirmation_add_to_shop_cart": "Are you sure you want to add product to shopping cart?",
            "hello": "Hello",
            "thanks": "Thanks",
            "regards": "Regards",
            "added_to_shopcart": "Successfully added to shopping cart!",
            "successfully_deleted": "Successfully deleted",
            "confirm_order_deleting": "You have decided to delete the order. Are you continue?",
            "payment_order_made_message": "Payment was made successfully. Thank you for your order",
            "reset_pass_desc": "You are receiving this email because we received a password reset request for your account",
            "reset_pass_trouble_info": "If you’re having trouble clicking the „Reset password” button, copy and paste the URL below\n",
            "mail_successfully_send": "Message was sent successfully",
            "mail_not_send": "The message has not been sent",
            "new_question": "New question",
            "customize_cookie_message": "You can configure cookies that are convenient for you, except for essential ones, the cancellation of which may affect the operation of the site",
            "required_cookie_fields_message": "These cookie fields are necessary for the website to function and cannot be turned off. Can you set your browser to block these cookies, but in that case it is possible that some sections of the site will stop working",
            "targeting_cookie_fields_message": "These cookie fields are set through our website by our partners. They can be used to collect data about your interests, pages visited and traffic sources in order to measure and improve our website",
            "cookie_apply_description": "By clicking the ':apply' button, you consent to the processing of cookies in accordance with :policy_link",
            "cookie_policy_link": "Cookie policy",
            "cookie_common_message": "We may process your personal data and use technologies such as cookies to provide services and keep statistics. You can change your choice at any time by clicking on \"Privacy Settings\" in the footer of the page and\/or in the browser settings. By using the website, you agree to the terms of personal data processing contained in the privacy policy.",
            "cookie_policy_desc": "This cookie is used to remember your cookie consent preferences.",
            "cookie_xsrf-token_desc": "This cookie is written to help with site security in preventing Cross-Site Request Forgery attacks",
            "cookie_laravel_session_desc": "Internally application uses :coockie_name to identify a session instance for a user",
            "targeting_coockie_desc": "Targeting cookies are used to identify visitors between different websites, e.g. content partners, advertising networks. These cookies may be used by companies to create profiles of user's interests or to display relevant advertisements on other websites.",
            "coockie_common_info": "Cookies are small text files that are stored on your computer by the websites you browse. Websites use cookies to help users navigate efficiently and perform certain functions. Cookies that are necessary for the website to function properly do not require your consent. All other cookies require consent before setting your browser to handle them.",
            "sending_notifications_acceptation": "I allow information to be sent via email",
            "using_private_data_acceptation": "I allow the use of my data",
            "read_and_accepted": "I have read and accepted",
            "policy_acceptation": "Ich erkläre, dass ich die <a class=\"acceptation-link\" href=\":policy_route\" target=\"_blank\" >:policy_link<\/a> gelesen habe. Ich erkläre, dass ich die <a class=\"acceptation-link\" href=\":acceptation_deny_route\" target=\"_blank\" >:acceptation_deny_link<\/a> gelesen habe",
            "updated_successfully": "Updated successfully",
            "deleted_successfully": "Deleted successfully",
            "created_successfully": "Created successfully",
            "added_successfully": "Added successfully",
            "imported_successfully": "Imported successfully",
            "something_was_wrong": "Oops. Something was wrong",
            "slug_exists_in_locale": "Such slug already exists in this locale",
            "order_without_parcels": "Order does not has parcels",
            "no_parcel_weight": "Parcel weight was not specified",
            "parcels_not_chosen": "Any parcels was not chosen",
            "thanks_for_order": "Thanks for order",
            "we_are_sorry": "We're sorry",
            "copyright": "Copyright © :current_year. Design by TivaTheme. All Rights Reserved",
            "to_big_size": "Too big size",
            "def_color_was_not_set": "Default color for box was not set or price (element - color group) was not set",
            "rol_sizes_error": "Rol sizes error",
            "shield\/box_price_was_not_set": "Shield box price for chosen sizes was not set",
            "such_box_doesnt_exist": "Such box does not exist",
            "such_structure_doesnt_exist": "Such structure does not exist",
            "such_shield_doesnt_exist": "Such shield does not exist",
            "such_box_seria_doesnt_exist": "Such box seria does not exist",
            "such_box_sizes_not available": "Such box sizes not available or was not set",
            "default_color_for_shield_was_not_set": "Default color for shield was not set",
            "default_color_for_bottom_band_was_not_set": "Default color for bottom band was not set",
            "confirmation_transfer_payment": "Confirmation transfer payment",
            "transfer_payment_request_is_processing": "Tansfer payment request is processing"
        },
        "configurators": {
            "box": "Box",
            "boxes": "Boxes",
            "system": "Structure",
            "hole_dimensions": "Hole dimensions",
            "holes_dimensions": "Hole dimensions",
            "shield_type": "Shield type",
            "shield_color": "Shield color",
            "bottom_band_color": "Bottom band color",
            "selected_structure_size": "Selected structure size",
            "box_color": "Box color",
            "guide_color": "Guide color",
            "guide_rotation": "Guide rotation",
            "manage_type": "Manage type",
            "roll_managing": "Roll managing",
            "manage_side": "Manage side",
            "mosquito_net": "Mosquito net",
            "width": "Width",
            "height": "Height",
            "qty": "Qty",
            "shield_not_available": "Shield not available for chosen sizes. Please change size",
            "cm": "cm",
            "hatch_color": "Inspection hatch\/plaster strip color",
            "plasterboard_type": "Plasterboard type",
            "box_sizes_not_available_or_not_chosen": "Box sizes not available or not selected",
            "manage_type_not_available": "Given dimensions make it impossible to produce such a roller blind",
            "remotes": "Remotes",
            "engines": "Engines",
            "remote": "Remote control",
            "shields": "Shields",
            "shield": "Shield",
            "shield_sizes": "Shield sizes",
            "includes_mosquito_net": "Includes mosquito net",
            "show_wood_colors": "Show wood colors",
            "prices_of_box_with_shield": "Prices of box with shield",
            "box_sizes": "Box_sizes",
            "box_size": "Box_size",
            "engine_type": "Engine type",
            "roll_type": "Roll type",
            "roll_subtype": "Roll subtype",
            "roll_subtypes": "Roll subtypes",
            "percent_per_color_group": "Percent per color_group",
            "elements_for_coloring": "Elements for coloring",
            "engine_group_remotes": "Engine group remotes",
            "engine_types": "Engine types",
            "rotation_types": "Rotation types",
            "manage_sides": "Manage sides",
            "drive_types_options": "Drive types (options)",
            "plasterboard_types": "Plasterboard types",
            "configurator_images": "Configurator images",
            "roll_structure": "Roll structure",
            "pairs_number_of_size_fields": "Pairs number of size fields",
            "colors_related_to_box": "Colors related to box",
            "colors_related_to_shield": "Colors related to shield",
            "colors_related_to_manual_driving": "Colors related to manual driving",
            "colors_sl_elements": "Colors\/Elements",
            "clinet_file": "Klapa rewizyjna\/listwa tynkowa"
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The provided password is incorrect.",
            "throttle": "Too many login attempts. Please try again in :seconds seconds.",
            "verify_your_email": "Verify your email",
            "check_your_email": "Please, check your mail",
            "if_didnt_get_link": "If you have not got link, please press to",
            "get_another_link": "get another link",
            "verify_click_text": "Click the button below to verify your email address",
            "verify_email": "Verify",
            "mail_click_trouble": "If you’re having trouble clicking the \":actionText\" button, copy and paste the URL below\n",
            "create_account": "Create account",
            "log_in": "Auth login",
            "are_you_forgot_your_pass": "Have you forgot password?",
            "reset_password": "Reset password"
        },
        "attribut": {
            "title": "Attributes for products",
            "description": {
                "index": "List",
                "show": "Item",
                "edit": "Edit",
                "create": "Create"
            }
        },
        "app": {
            "nav": {
                "howToMeasure": "jak-mierzyc",
                "gdpr": "rodo",
                "aboutUs": "o-nas",
                "contacts": "kontakt",
                "howToMount": "jak_montowac"
            },
            "status": "Status",
            "status_active": "Active",
            "status_inactive": "Inactive",
            "yes": "Yes",
            "no": "No",
            "locale": "Locale",
            "new": "New",
            "admin_panel_header": "Admin panel",
            "home_page": "Main page",
            "categories": "Categories",
            "new_products": "New products",
            "products_available": "Available",
            "catalog": "Catalog",
            "buy_together": "Buy together",
            "attributes": "Atrybuty",
            "options": "Options",
            "paired_products": "Paired products",
            "related_products": "Related products",
            "login": "Login",
            "registration": "Registration",
            "shop_cart": "Shopping cart",
            "password": "Password",
            "email": "Email",
            "first_name": "First name",
            "last_name": "Last name",
            "create": "Create",
            "password_confirmation": "Password confirmation",
            "phone": "Phone",
            "firm_name": "Firm name",
            "new_password": "New password",
            "nip": "NIP",
            "save": "Save",
            "edit": "Edit",
            "menu": "Menu",
            "edit_profile": "Edit profile",
            "orders_history": "Orders history",
            "my_account": "My account",
            "account": "Account",
            "logout": "Log out",
            "address": "Address",
            "country": "Country",
            "region": "Region",
            "city": "City",
            "street": "Street",
            "post_code": "Post code",
            "purchase": "Purchase",
            "private_person": "Private person",
            "account_type": "Account_type",
            "firm": "Firm",
            "personal_information": "Personal information",
            "rol_rotation_a": "Rotation guides A",
            "rol_rotation_b": "Rotation guides B",
            "color": "Color",
            "products": "Products",
            "status_new": "New",
            "status_in_progress": "In progress",
            "status_rejected": "Rejected",
            "status_taken": "Taken",
            "status_is_send": "Sent",
            "status_returned": "Returned",
            "status_confirmed": "Confirmed",
            "send": "Send",
            "message": "Message",
            "discount": "Discount",
            "discount_in_percent": "Discount in percent",
            "date_from": "From",
            "date_to": "To",
            "is_active": "Is active",
            "coupon": "Coupon",
            "search": "Search",
            "search_descr": "FIND YOUR PRODUCT WITH FAST SEARCH. ENTER SOME KEYWORD SUCH AS DRESS, SHIRTS, SHOES ETC. OR CAN SEARCH BY PRODUCT SKU.",
            "value_is_translatable": "To translate",
            "select": "Select",
            "description": "Description",
            "reviews": "Reviews",
            "free_delivery_from": "FREE DELIVERY FROM",
            "full_name": "Full name",
            "submit": "Submit",
            "comment": "Comment",
            "your_rating": "Your rating",
            "to_cart": "Add to cart",
            "shop_reviews": "Shop reviews",
            "contact": "Shop reviews",
            "unit_g": "gm",
            "unit_mm": "mm",
            "unit_m2": "m2",
            "unit_kg": "kg",
            "unit_cm": "cm",
            "unit_pcs": "pcs",
            "send_reset_link": "Send reset link",
            "password_reset": "Password reset",
            "back_to_login": "Back to login",
            "password_resetting": "Password resetting",
            "agreement_payment_menu": "AGB",
            "contact_us": "Contact us",
            "engine": "Engine-2",
            "type": "Type",
            "mosquito_net": "Mosquito net",
            "open_shopcart": "Open shopcart",
            "continue": "Continue",
            "add": "Add",
            "cancel": "Cancel",
            "download_pdf": "Download pdf",
            "offer": "Offer",
            "data": "Data",
            "delivery": "Delivery",
            "paid": "Paid",
            "total": "Total",
            "quantity_short": "QTY",
            "review": "Review",
            "read_reviews": "Read reviews",
            "write_review": "Write review",
            "items_count_in_shopcart": "There are :quantity item(s) in your cart",
            "total_products": "Total products",
            "total_delivery": "Total delivery",
            "for_free_delivery": "For free delivery",
            "free": "Free",
            "delete": "Delete",
            "main_data": "Main data",
            "returning_form": "Returning form",
            "order_number": "Order number",
            "returning_reason": "Returning reason",
            "returning": "Returning",
            "order": "Order",
            "free_delivery": "Free delivery",
            "id": "ID",
            "name": "Name",
            "name_short": "Name short",
            "title": "Title",
            "meta_title": "Meta title",
            "meta_description": "Meta description",
            "parent_id": "Parent",
            "icon": "Icon",
            "uri": "Uri",
            "created_at": "Created At",
            "updated_at": "Updated At",
            "discount_placeholder": "Enter Discount",
            "discount_helper": "Discount in %",
            "remember_token": "Remember Token",
            "email_verified_at": "Email verified at",
            "main_category_id": "Main category",
            "main_category_id_helper": "Specify the menu item for the product",
            "category": "Category",
            "price": "Price",
            "price_from": "Price from",
            "price_placeholder": "Enter price",
            "price_helper": "Price",
            "img": "Image",
            "image": "Image",
            "images": "Images",
            "url": "URL",
            "video_url": "URL to video",
            "quantity": "Quantity",
            "group": "Group",
            "calculation_type": "Calculation type",
            "is_available": "Enabled",
            "deliverymethods_name": "Delivery method name",
            "deliverymethods_price": "Shipping cost",
            "productsfixed_main_image": "Main image",
            "productsfixed_images": "Additional images",
            "options_helper": "Select the required parameters from the list and enter the value.",
            "slug": "Slug",
            "slug_help": "The inscription will be visible in the address bar. Generated automatically or can be set manually",
            "slider_alt_helper": "A message will be displayed if photos are disabled",
            "picture": "Picture",
            "text": "text",
            "content": "content",
            "product": "Product to display",
            "product_name_helper": "Name of the product that will be displayed when clicking on the menu specified above",
            "value": "Value",
            "bound_categories": "Bound categories",
            "available": "Available",
            "producer": "Producer",
            "vat": "Vat",
            "price_net": "Net price",
            "price_gross": "Gross price",
            "new1": "sds",
            "months": {
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December"
            },
            "acceptation": "Acceptations",
            "sender": "Sender",
            "recipient": "Recipient",
            "house_number": "House number",
            "apply": "Apply",
            "close": "Close",
            "weight": "Weight",
            "boxes_for_sending": "Parcels for sending",
            "availability": "Availability",
            "many_available": "Large amount",
            "little_available": "Small amount",
            "currently_not_available": "Currently not available",
            "customize": "Customize",
            "back": "Back",
            "required_fields_cookie": "Required files cookie",
            "targeting_fields_cookie": "Targeting fields cookie",
            "cookie_policy": "Cookie policy",
            "shown_num_items_from_num": "Shown :shown_num items from :total_num",
            "others": "Others",
            "configure": "Configure",
            "name_from_dictionary": "Translation from dictionary",
            "first_name_and_last_name": "First name and last name",
            "order_as_guest": "Order as a guest",
            "another_delivery_address": "Another delivery address",
            "payment": "Payment",
            "sending_notifications_acceptation": "I allow information to be sent via email",
            "using_private_data_acceptation": "I allow the use of my data",
            "read_and_accepted": "I have read and accepted",
            "seller_notes": "Note for seller",
            "about_us": "About us",
            "blog": "Blog",
            "read_more": "Read more",
            "files": "Files",
            "delivery_address": "Delivery address",
            "chosen_by_default": "Chosen by default",
            "delivery_method": "Delivery method",
            "price_per_kg": "Price per kg",
            "agreement_to_pay_for_order": "Agreement to pay",
            "import": "Import",
            "engine_groups": "Engine groups",
            "additional_settings": "Additional settings",
            "subtype": "Subtype",
            "by_default": "By default",
            "size": "Size",
            "write_price": "Write price",
            "max_height": "Max height",
            "min_height": "Min height",
            "max_width": "Max width",
            "min_width": "Min width",
            "colors": "Colors",
            "editing": "Editing",
            "deleting": "Deleting",
            "creating": "Creating",
            "updating": "Updating",
            "color_group": "Color group",
            "color_groups": "Color groups",
            "color_subgroup": "Color subgroup",
            "color_subgroups": "Color subgroups",
            "create_new": "Create new",
            "percent": "Percent",
            "element": "Element",
            "model": "Model",
            "serial_number": "Serial number",
            "calculation_factor": "Calculation factor",
            "example": "Example",
            "example_for": "Example for",
            "sizes": "Sizes",
            "colors_are_required": "Colors are required",
            "orders": "Orders",
            "customer": "Customer",
            "max_square": "Max square",
            "weight_per": "Weight per",
            "allowed_size": "Allowed size",
            "main_category": "Main category",
            "code": "Code",
            "reusable": "Reusable",
            "parameters": "Parameters",
            "price_per_item": "Price per item",
            "check_status": "Check status",
            "download": "Download",
            "total_price": "Total price",
            "dpd_delivery_status": "Dpd delivery status",
            "date": "Date",
            "products_price": "Products price",
            "notes": "Notes",
            "optionally": "Optionally",
            "auto_slug_desc": "If empty will be generated from name",
            "user_name": "User name",
            "rating": "Rating",
            "print_dpd_labels": "print_dpd_labels",
            "brand_rating": "Brand rating",
            "shop_products_rating": "Shop products rating",
            "orders_number": "Orders number",
            "revenue": "Revenue",
            "average_order_value_sl": "Average order <br> value",
            "unique_orders": "Unique orders",
            "conversion_rate_e-commerce_sl": "Conversion rate <br> e-commerce",
            "number_of_registered": "Number of registered",
            "orders_number_from_unregistered_sl": "Orders number from <br> unregistered users",
            "orders_number_from_registered_sl": "Orders number from <br> registered users",
            "user_registration_by_year": "User registration by year",
            "weight_and_dimensions": "Weight and dimensions",
            "height_from": "Height from",
            "height_to": "Height to",
            "working_on_site": "Working on site",
            "allowed_ips": "Allowed ip",
            "subject": "Subject",
            "select_color_and_mark_price": "Select the colors and mark the price adjustment percentage",
            "subcategories": "Subcategories",
            "valuation_applies": "Valuation applies",
            "box_cross-section": "Box cross-section",
            "chose_translation": "Chose translation",
            "create_new_translation": "Create new translation",
            "delivery_price": "Delivery price",
            "new_paid_order": "New paid order",
            "status_changed": "Status changed",
            "status_changed_from": "Status changed from \":status_from\" to \":status_to\"",
            "from_smb": "From",
            "to_smb": "To",
            "back_to_home_page": "Back to homepage",
            "no_any_reviews": "Obecnie nie ma żadnych opinii",
            "send_message": "Send message",
            "information": "Information",
            "data_protection": "Data protection",
            "legal_info": "Impressum",
            "follow_us_on": "Follow Us On",
            "subscribe": "Subscribe",
            "newsletter": "Newsletter",
            "newsletter_desc": "Sign up to our newsletter to get the latest articles, lookbooks voucher codes direct to your inbox",
            "free_delivery_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "per_page": "Per page",
            "from_qty": "From",
            "to_qty": "To",
            "sort_a_z": "A to Z",
            "sort_z_a": "Z to A",
            "sort_high_to_low": "Low to high",
            "sort_low_to_high": "High to low",
            "sort_by": "Sort by",
            "new_products_desc": "LOREM IPSUM DOLOR SIT AMET CONSECTETUR",
            "find_more": "Find more",
            "nothing_to_pay": "Nothing to pay",
            "shop_cart_is_empty": "Shopcart is empty",
            "view_cart": "View Cart",
            "gdpr": "GDPR",
            "regulations": "Regulations",
            "withdrawal_rights": "Widerrufsbelehrung",
            "coockies": "Cookies",
            "find_out_more": "Find out more",
            "discard_all": "Discard all",
            "apply_all": "Apply all",
            "control_your_privacy": "Control your privacy",
            "cookie_statement": "Cookie Statement",
            "about_coockie": "About cookie",
            "cookie_report": "Cookie report",
            "provider": "Provider",
            "domain": "Domain",
            "storage_period": "Storage period",
            "year_s": "Year(s)",
            "hour_s": "Hours(s)",
            "day_s": "Day(s)",
            "targeting": "Targeting",
            "details": "Details",
            "main_page_foot_section_2_title": "FREE INSTALLATION",
            "main_page_foot_section_2_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "main_page_foot_section_3_title": "MONEY BACK GUARANTEED",
            "main_page_foot_section_3_desc": "Lorem ipsum dolor Sed ut perspiciatis unde omnis",
            "transfer": "",
            "payment_failed": "Payment failed",
            "new_order": "New order",
            "is_paid": "Is paid",
            "rolls_free_delivery_from": "Free delivery of rolls from",
            "for_free_rolls_delivery": "For free rolls delivery",
            "gray": "Gray",
            "width": "Width",
            "height": "Height",
            "wood-like_colors_veneers": "Wood-like colors - veneers",
            "color_code": "Color code",
            "free_delivery_by_buying_configured_rolet": "Free delivery if to buy configured rolet",
            "category_id": "Category"
        },
        "admin": {
            "online": "Online",
            "login": "Login",
            "logout": "Logout",
            "setting": "Settings",
            "name": "Name",
            "username": "Username",
            "password": "Password",
            "password_confirmation": "Password confirmation",
            "remember_me": "Remember me",
            "user_setting": "User setting",
            "avatar": "Avatar",
            "list": "List",
            "new": "New",
            "create": "Create",
            "delete": "Delete",
            "remove": "Remove",
            "edit": "Edit",
            "view": "View",
            "browse": "Browse",
            "reset": "Reset",
            "export": "Export",
            "batch_delete": "Batch delete",
            "batch_disable": "Batch disable",
            "batch_update_price": "Batch price update",
            "save": "Save",
            "refresh": "Refresh",
            "order": "Order",
            "expand": "Expand",
            "collapse": "Collapse",
            "filter": "Filter",
            "close": "Close",
            "show": "Show",
            "entries": "entries",
            "captcha": "Captcha",
            "action": "Action",
            "title": "Title",
            "description": "Description",
            "back": "Back",
            "back_to_list": "Back to List",
            "submit": "Submit",
            "menu": "Menu",
            "input": "Input",
            "succeeded": "Succeeded",
            "failed": "Failed",
            "disable_confirm": "Are you sure to disable this item ?",
            "update_confirm": "Are you sure to update this item?",
            "delete_confirm": "Are you sure to delete this item ?",
            "delete_succeeded": "Delete succeeded!",
            "delete_failed": "Delete failed!",
            "disable_succeeded": "Disable succeeded!",
            "disable_failed": "Disable failed!",
            "update_succeeded": "Update succeeded!",
            "update_failed": "Update failed!",
            "save_succeeded": "Save succeeded!",
            "refresh_succeeded": "Refresh succeeded !",
            "login_successful": "Login successful",
            "choose": "Choose",
            "choose_file": "Select file",
            "choose_image": "Select image",
            "more": "More",
            "deny": "Permission denied",
            "administrator": "Administrator",
            "roles": "Roles",
            "permissions": "Permissions",
            "slug": "Slug",
            "created_at": "Created At",
            "updated_at": "Updated At",
            "alert": "Alert",
            "parent_id": "Parent",
            "icon": "Icon",
            "uri": "URI",
            "operation_log": "Operation log",
            "parent_select_error": "Parent select error",
            "role": "Role",
            "permission": "Permission",
            "route": "Route",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "all_methods_if_empty": "All methods if empty",
            "all": "All",
            "current_page": "Current page",
            "selected_rows": "Selected rows",
            "upload": "Upload",
            "new_folder": "New folder",
            "time": "Time",
            "size": "Size",
            "module_title": ":module module",
            "price_type_fixed": "Fixed amount",
            "price_type_percentage": "% amount",
            "pagination": {
                "range": "Showing :first to :last of :total entries"
            },
            "http": {
                "method": "HTTP method",
                "path": "HTTP path"
            },
            "listbox": {
                "text_total": "Showing all {0}",
                "text_empty": "Empty list",
                "filtered": "{0} \/ {1}",
                "filter_clear": "Show all",
                "filter_placeholder": "Filter"
            },
            "module_description": {
                "index": "List",
                "show": "Show",
                "edit": "Edit",
                "create": "Create",
                "detail": "Detail"
            },
            "search": "Search 1",
            "menu_titles": {
                "index": "Index",
                "site_menu": "Site menu(Categories)",
                "posts": "Posts",
                "pages": "Pages",
                "coupons": "Coupons",
                "products": "Products",
                "attributes": "Attributes",
                "options": "Options",
                "option_values": "Option values",
                "units": "Units",
                "opinions": "Opinions",
                "configurators": "Configurators",
                "configurator_types": "Configurator types",
                "external_rolls": "External rolls",
                "roll_subtypes": "Subbtype rolls",
                "roll_boxes": "Boxes",
                "roll_shutter_arrangements": "Shutter arrangements",
                "roll_shields": "Shields",
                "roll_shield_box_prices": "Shield-box prices",
                "roll_box_sizes": "Box sizes",
                "roll_box_size_dependencies": "Box sizes(dependencies)",
                "roll_drive_types": "Drive types",
                "roll_drive_types_options": "Drive types (options)",
                "roll_manage_sides": "Manage sides",
                "roll_rotation_types": "Rotation types",
                "roll_elements_for_coloring": "Elements for coloring",
                "roll_engine_types": "Engine types",
                "roll_engines": "Engines",
                "colors": "Colors",
                "plasterboard_types": "Plasterboard types",
                "color_groups": "Color groups",
                "adding_color_to_element": "Assigning color to element",
                "percent_setup_for_color_groups": "Percent setup for color groups",
                "additional_settings": "Additional settings",
                "sliders": "Slides",
                "site_users": "Site users",
                "settings": "Settings",
                "adminpanel": "Adminpanel",
                "admin_users": "Adminpanel users",
                "delivery_methods": "Delivery methods",
                "logging": "Logging",
                "roles": "Roles",
                "permissions": "Permissions",
                "admin_menu_settings": "Admin menu settings",
                "operation_log": "Operation log",
                "orders": "Orders",
                "mailing": "Mailing",
                "order_returns": "Order returns",
                "reports": "Reports",
                "selling_report": "Selling report",
                "client_statistic": "Client statistic",
                "best_products_and_marks": "Best products and marks",
                "search_queries": "Search queries",
                "shop_reviews": "Shop reviews",
                "app_translations": "App translations",
                "client_translations": "Client translations",
                "engine_groups": "Engine groups",
                "engine_group_remotes": "Engine group remotes",
                "region_selling_statistic": "Region selling statistic",
                "configurator_previews": "Configurator preview images"
            }
        }
    }
}