

import {translations} from './../lang/translations'
// import swal from "sweetalert"; // if uncomment wrong category deleting

// console.log('wt',window.transitions,LA.locale,window.transitions[LA.locale]);
window.trans = function(path){
    // if(window.lang_arr) {
    // console.log('trans',window.lang_arr.strings.date_cannot_be_greater_than, path);
    let slugs = path.split('.');
    // console.log(slugs);
    if(slugs.length > 0) {
        //     return translations.strings[path];
        // } else {
        let res = window.translations[LA.locale];
        // console.log('res',res);
        for(let i = 0; i<slugs.length; i++) {
            res = res[slugs[i]];
            // console.log(slugs[i],res);
            if(!res){
                return path;
            }
        }
        return  res;
    }
    return path;
    // }
    // return path;
}
window.translations = translations;
// console.log(LA.locale,translations,trans('app.value_is_translatable'));
$(document).ready(function(){
    bindPasswordShowEvent(true);
});

function bindPasswordShowEvent(firstInit){

    let passInputs = $('.input-group-addon').next('input[type=password]');
    let addons = [];
    for(let i=0; i<passInputs.length; i++){
        addons.push(passInputs.eq(i));
        console.log(passInputs.eq(i).prev('.input-group-addon'));
        passInputs.eq(i).prev('.input-group-addon').addClass('toggle-pass-type').addClass('cursor-pointer');
    }
    if(firstInit){
        $('body').on('click','.toggle-pass-type',function(){

            let t = $(this);
            if(t.next('input').attr('type') == 'password'){
                t.next('input').attr('type','text');
            } else {
                t.next('input').attr('type','password');
            }
        });
    }
}



window.processResponseSuccess = function(data){
    if (data.success) {
        toastr['success'](LA.toast_error);
        // $('.success-ajax-container').css('display', 'block').find('ul').append('<li>' + data.success + '</li>');
    } else if(data.sweet_success){
        swal('', data.sweet_success, 'success')
    }
}
window.processResponseError = function(data){
    if (data.error) {
        toastr['error'](data.error);
    } else if(data.sweet_error){
        swal('', data.sweet_error, 'error')
    }
}
window.processResponseErrors = function(data){

    var errors = $.parseJSON(data.responseText);


    if (errors.error) {
        toastr['error'](errors.error);
    } else if(data.sweet_error){
        swal('', data.sweet_error, 'error')
    }

    if(data.status == 422 && errors.errors){
        let html = '<ul class="request-errors-container">';
        $.each(errors.errors, function (key, value) {

            html+='<li>' + value + '</li>';
        });
        html+= '</ul>';
        toastr['error'](html);
    }

}
$('.client-date-time').each(function(ind,el){
    // console.log();
    // let date = el.data('date');
    // let format = el.data('date-format');
});
// if(LA.toast_success){
//     window.toastr('success',LA.toast_success);
// }
