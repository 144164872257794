require('./bootstrap');

window.Vue = require('vue');

window.$ = window.jQuery = require('jquery');
window.toastr = require('toastr');

import 'bootstrap';

window.Parsley = require('parsleyjs');
require('parsleyjs/src/i18n/en.js');
require('parsleyjs/src/i18n/pl.js');
require('parsleyjs/src/i18n/de.js');
window.Parsley.setLocale(LA.locale);


var Inputmask = require('inputmask');

window.moment = require('moment');
moment.locale(LA.locale);

import 'select2'
import swal from 'sweetalert';
// import {default as swal2}  from 'sweetalert2'
window.swal2 = require('sweetalert2')


if(LA.toast_success){
    setTimeout(function(){toastr['success'](LA.toast_success);},1000)
} else if(LA.toast_error){
    setTimeout(function(){toastr['error'](LA.toast_error);},1000)
}
if(LA.sweet_error){
    swal('', LA.sweet_error, 'error');
} else if(LA.sweet_success){
    swal('', LA.sweet_success, 'success');
}
// if(typeof toast != 'undefined') {
//     toastr[toast.type](toast.message);
// }


// console.log('trans',trans('app.status'));


require('bootstrap-datepicker')

require( 'datatables.net-dt' );
let dtLocale = require('datatables.net-plugins/i18n/en-GB.js');
if(LA.locale === 'pl'){
    dtLocale = require('datatables.net-plugins/i18n/pl.js');
}
window.dtLocale = dtLocale;

function debounce(fn, delay = 300) {
    var timeoutID = null;

    return function () {
        clearTimeout(timeoutID);

        var args = arguments;
        var that = this;

        timeoutID = setTimeout(function () {
            fn.apply(that, args);
        }, delay);
    }
}
Vue.directive('debounce', (el, binding) => {
    if (binding.value !== binding.oldValue) {
        // window.debounce is our global function what we defined at the very top!
        el.oninput = debounce(ev => {
            console.log('works');
            el.dispatchEvent(new Event('change'));
        }, parseInt(binding.value) || 300);
    }
});
// alert('here11');
Vue.filter('highlight', function (value) {
    return value.replace(new RegExp(value, 'gi'), '<span class="highlighted">$&</span>');
});
// import Vuebar from 'vuebar';
// Vue.use(Vuebar);
// Vue.component('userData', require('./components/UserDataFormComponent.vue').default);
// Vue.component('searching', require('./components/SearchingComponent.vue').default);
// Vue.component('btnBasket', require('./components/BtnBasket').default);
const app = new Vue({
    el:'#app',
            data(){
        return {
            showMobileMenu: false,
            csrf: document.head.querySelector('meta[name="csrf-token"]').content
        }
    },

    // computed: {
    //     results() {
    //         return this.keywords ? this.products.filter(result => result.title.includes(this.keywords)) : [];
    //     },

    // },
});


